import React, { useEffect, useState } from "react";
import OurLocation from "../../Components/our-location/our-location";
import WelcomeToAqualina from "../../Components/welcome-to-aqualina/welcome-to-aqualina";
import JoinAfrica from "../../Components/join-africa/join-africa";
import backgroundImage from "../../images/Rectangle75.png";
import locationImage from "../../images/location image.png";
import welcomImageOne from "../../images/ladyhat.png";
import welcomeImageTwo from "../../images/poolside.png";
import privateEventsImage from "../../images/private.png";
import privateEventsImageMobile from "../../images/privatemobile.png"
import AboutUs from "../../Components/about-us/about-us";
import Joievivreimageone from "../../images/twoseats.png";
import Joievivreimagetwo from "../../images/lounge.png";
import joievivreimageonemobile from "../../images/aboutonemobile1.png";
import joievivreimagetwomobile from "../../images/aboutonemobile2.png";
import RayWaves from "../../Components/ray-waves/ray-waves";
import raywavesImage from "../../images/raywaves.png";
import spotifyImage from "../../images/spotifypic.png";
import locationimageMobile from "../../images/locationmobile.png";
import welcomeimagetwomobile from "../../images/welcomeimage2mobile.png";
import welcomeimageonemobile from "../../images/ladyhatmobile.png";
import PrivateEvents from "../../Components/private-events/private-events";
import playImage from "../../images/play.png";
import founderImage from "../../images/founderweb.png";
import foundermobile from "../../images/foundermobile.png";
import FounderCardLeft from "../../Components/founder-card-left/founder-card-left";
import FounderCardRight from "../../Components/founder-card-right/founder-card-right";
import managerweb from "../../images/2ndfounderweb.png";
import managermobile from "../../images/2ndfoundermobile.png";
import hdrowweb from "../../images/hdrow-web.png";
import hdrowmobile from "../../images/hdrow-mobile.png";
import RoomCards from "../../Components/room-cards/room-cards";
import aquaImage from "../../images/aqua.png";
import linaImage from "../../images/lina.png";
import mastersimage from "../../images/master.png";
import RoomDetail from "../../Components/room-detail/room-detail";
import aquafullimage from "../../images/fullaqua.png";
import mobilefullaqua from "../../images/fullaqua.png";
import bullet from "../../images/bullet.png";
import RoomFeatures from "../../Components/room-features/room-features";
import AqualinaExudes from "../../Components/aqualina-exudes/aqualina-exudes";
import exudesImageOne from "../../images/exudesone.png";
import exudesImageTwo from "../../images/exudestwo.png";
import exudesImageThree from "../../images/exudesthree.png";
import exudesmobileimg1 from "../../images/exudesmoblileone.png";
import exudesmobileimg2 from "../../images/exudesmobiletwo.png";
import exudesmobileimg3 from "../../images/exudesmobilethree.png";
import Experience from "../../Components/experience/experience";
import fullImage from "../../images/experience-couple.png";
import experienceimgone from "../../images/experience-ladies.png";
import experienceimgtwo from "../../images/experience-book.png";
import ShopComingSoon from "../../Components/shop-coming-soon/shop-coming-soon";
import shoplogo from "../../images/shoplogo.png";
import shopmainimg from "../../images/shopmainimage.png";
import instagram from "../../images/insta.png";
import facebook from "../../images/facebook.png";
import tiktok from "../../images/tiktok.png";
import whatsapp from "../../images/whatsapp.png";
import tmarkimage from "../../images/shopVector.png";
import ContactForm from "../../Components/contact-form/contact-form";
import nonfullimage from "../../images/notfullimage.png";
import video from "../../images/location.mp4";
import { ApiManager } from "../../Utilities/api-manager";


export default function TestPage() {

    const roomFeatures = [
        "Free Wi-Fi Internet access",
        "Smart TV with Netflix",
        "Bed Type: Double Bed Size",
        "Safe deposit box laptop size",
        "In-built sound system ",
        "Sliding Door: to Deck and Lap Pool",
        "Beach towels, slippers & bathrobes",
        "Shower and Jacuzzi",
        "Tea & Coffee making facilities",
        "Air-condition individually controlled",
        "Mini Fridge",
        "Bed linen and Towel change"
    ]
    const cardsarrySection = [
        {
            description: "These Mediterranean-styled rooms feature a luxurious king bed and en-suite bathroom.",
            imageUrl: aquaImage,
            title: "Lina Rooms"
        },
        {
            description: "These Mediterranean-styled rooms feature a luxurious king bed and en-suite bathroom.",
            imageUrl: aquaImage,
            title: "Lina Rooms"
        },
        {
            description: "These Mediterranean-styled rooms feature a luxurious king bed and en-suite bathroom.",
            imageUrl: aquaImage,
            title: "Lina Rooms"
        }
    ]


    useEffect(() => {
        fetchInfo();
    }, []);

    const fetchInfo = async () => {
        // const response = await fetch("https://aqualinabe.osigla.com.ng/page/rooms");
        const response = await ApiManager("/page/rooms", "GET");
        if (response) {
            console.log({ response: response });

        }

    }

    return (
        <>

        

            {/* <OurLocation
                title="OUR LOCATION"
                caption="Ilashe"
                text="A perfect retreat for those living a lifestyle under the sun."
                buttontext="Discover More"
                image={locationImage}
                locationPlay={playImage}
                mobileimage={locationimageMobile}
                link="/"
                video={video}
            />

            <WelcomeToAqualina
                link="/"
                title="WELCOME TO AQUALINA"
                caption="Sharing Our Passion for the Beach Lifestyle"
                imageOne={welcomImageOne}
                mobileimageOne={welcomeimageonemobile}
                imageTwo={welcomeImageTwo}
                mobileimageTwo={welcomeimagetwomobile}
                text="From the moment you enter, Aqualina exudes refined 
            elegance with a laidback vibe within a tranquil, 
            waterfront cocoon. Every detail of the beach house 
            is meticulously crafted with its modern Mediterranean-inspired 
            design featuring clean lines, modern décor, and a white exterior 
            accentuated by the azure ocean backdrop. Revel in the picturesque 
            views of swaying palm trees, powder-white sands, and captivating 
            vistas of the Atlantic Ocean."
                buttontext="Learn More" />

            <JoinAfrica
                backgroundimage={backgroundImage}
                title="MEMBERSHIP"
                buttontext="Apply for Aqualina Membership"
                bodytext="Join Africa’s most iconic private members’ beach club, and our community of 
            trendsetters and tastemakers"
                altBodyText=""
                link="/" />

            <PrivateEvents
                backgroundimage={privateEventsImage}
                mobilebackgroundimage={privateEventsImageMobile}
                title="PRIVATE EVENTS"
                buttontext="View Events Calendar"
                bodytext="Get Invited to"
                altBodyText="Aqualina Events"
                link="/" />

            <AboutUs
                imageOne={Joievivreimageone}
                imageTwo={Joievivreimagetwo}
                imageOneMobile={joievivreimageonemobile}
                imageTwoMobile={joievivreimagetwomobile}
                caption="Joie de vivre"
                captext="Our passion for the beach culture is rooted in the sense of freedom 
                and joy of living at the beach."
                text="Our story began over seven years ago, when Femi Fowora, a renowned Nigerian visionary 
            and tastemaker, created Aqualina as his private beach house. Drawing inspiration from 
            his extensive lifestyle experiences and profound appreciation for the finer things in 
            life, Femi crafted a space that embodies comfort, elegance, and unrivaled hospitality. 
            Today, Aqualina Beach House welcomes trendsetters who share a vision for the ultimate 
            beach lifestyle, embracing the spirit of Femi's original vision. As part of Henderson 
            Row Ltd, our parent company, we aim to export the vibrant spirit of African culture and 
            hospitality to the world’s most stylish destinations."/>

            <RayWaves
                link="/"
                title="RAYS & WAVES"
                bodytext="Welcome to our world- Rays & Waves Our"
                textaccent="passion"
                buttontext="Explore Our Blog"
                backgroundimage={raywavesImage}
                track="Beauty Behind The Madne..."
                artiste="The Weekend"
                sportifyimage={spotifyImage}
                seeplaylist="See Playlist" />

            <FounderCardLeft
                text="Mr. Femi Fowora, the esteemed founder and Chairman of Henderson Row Ltd, serves as 
         the driving force behind Aqualina Beach House and Mansa Beach Club. With his visionary 
         leadership, Henderson Row is set to revolutionize the luxury beach club industry, starting 
         with the development of the world's premier Afrobeats-themed beach club in Lagos, Nigeria, 
         and ambitious plans for expansion across Africa.Boasting an impressive career spanning over 
         25 years, Mr. Fowora is a seasoned executive who possesses a deep understanding of Nigeria's 
         financial market. He has achieved remarkable success in various sectors, including commodities 
         brokerage, capital raising, real estate development, hospitality, security, printing, 
         and marketing."
                title="FOUNDER & CHAIRMAN"
                name="Femi Fowora"
                company="HENDERSON ROW LIMITED"
                image={founderImage}
                imageMobile={foundermobile}
                buttontext="Read More"
                hidebutton={false}
                width="100%"
                bottommargin="0px"
                link="/" />

            <FounderCardRight
                name="Marylee Martucci"
                title="GROUP GENERAL MANAGER"
                company="HENDERSON ROW LIMITED"
                buttontext="Read More"
                text="Marylee Martucci is a seasoned professional with over 17 years of experience in 
         business administration, including 12 years working in Nigeria. Her diverse background 
         encompasses overseeing daily operations, driving business development, and event management. 
         Marylee brings a professional, energetic, and versatile approach to her work. Having excelled 
         in navigating complex environments, Marylee possesses a deep understanding of the local 
         business landscape and brings a unique global perspective to the Aqualina team."
                image={managerweb}
                imageMobile={managermobile}
                link="/" />

            <FounderCardLeft
                text="Henderson Row Limited is a global hospitality group operating from West Africa with 
         two core subsidiaries- Aqualina Beach House and Mansa Beach Club. Henderson Row Limited 
         plans on opening multiple concepts and destinations over the next ten years. We work with 
         the best talent and collaborators globally- fashion shows, finance partners, renowned chefs 
         and top chart DJ’s. Our purpose is to curate an unforgettable experience and tell our 
         African lifestyle story to the world."
                title="HOLDING COMPANY"
                name="Henderson Row"
                company="AQUALINA BEACH HOUSE | MANSA BEACH CLUB"
                image={hdrowweb}
                imageMobile={hdrowmobile}
                buttontext=""
                hidebutton={true}
                width="70%"
                bottommargin="100px"
                link="/" />

            <RoomCards
                title="ALL ROOMS AND SUITES"
                cardArray={cardsarrySection}
                buttonText="Discover More" />

            <RoomDetail
                roomImage={aquafullimage}
                mobileroomImage={mobilefullaqua}
                title="Our Aqua Rooms offer the perfect balance of comfort, luxury and 
        mesmerising views that will take your breath away."
                text="The lavish Aqua rooms have a double bedroom with its own small lounge, 
        that opens up to the pool deck. It offers the full  experience for you and your 
        friends. Beautifully furnished with warm earthy colours and top quality finishes. 
        The distinctive sunlight of the West African coast illuminates these spacious Aqua rooms, 
        where a sitting area connected to its exterior looks onto the Atlantic Ocean creating a 
        space that welcomes you each evening."
                buttonText="Reserve Now"
                number="Number of Rooms: 1"
                size="Room Size: 20 sqm"
                bath="Bathroom Size: 8 sqm"
                guests="Guests: 3 adults"
                bedding="Bedding: Double Bed (Full Bed)"
                link="/" />


            <RoomFeatures
                title="FEATURES & AMENITIES"
                featuresArray={roomFeatures}
                image={bullet} />

            <AqualinaExudes
                caption="Aqualina exudes a laidback vibe with a sunset party atmosphere. Cuisine and 
        cocktails are complemented by great music and entertainment."
                imageOne={exudesImageOne}
                imageTwo={exudesImageTwo}
                imageThree={exudesImageThree}
                imageOneMobile={exudesmobileimg1}
                imageTwoMobile={exudesmobileimg2}
                imageThreeMobile={exudesmobileimg3} />

            <Experience
                fullWidth={true}
                fullImage={true}
                imageOne={fullImage}
                imageTwo=""
                captionOne="Founder's Culinary Evening"
                captionTwo=""
                textOne="Immerse yourself in an unforgettable culinary experience at our renowned Founder's 
        Culinary dining event. With a perfect blend of tantalizing dishes, exceptional wines, and 
        ambient music, this members-only occasion showcases the true essence of Aqualina's 
        entertaining prowess and is not to be missed."
                textTwo="" />

            <Experience
                fullWidth={true}
                fullImage={false}
                imageOne={nonfullimage}
                imageTwo=""
                captionOne="Founder's Culinary Evening"
                captionTwo=""
                textOne="Immerse yourself in the magic of cinema as the sun sets and the stars come alive at 
        Aqualina Beach House. Experience the ultimate movie night on the beach, where you can 
        indulge in delectable food and drinks while enjoying your favorite films. With the gentle 
        ocean breeze and the mesmerizing backdrop of the starry sky, this enchanting cinematic 
        experience promises an unforgettable evening of luxury and relaxation."
                textTwo="" />

            <Experience
                fullWidth={false}
                fullImage={false}
                imageOne={experienceimgone}
                imageTwo={experienceimgtwo}
                captionOne="Arts & Culture"
                captionTwo="Rest & Relaxation"
                textOne="Aqualina's commitment to the vibrant Nigerian arts takes many forms, from supporting
         local artists to hosting captivating exhibitions. Prepare to be dazzled as we bring you 
         fashion shows in collaboration with Lagos Fashion Week, featuring international fashion 
         houses and exclusive retail pop-ups. Our shops also offer unique collections, including 
         branded t-shirts, towels, hats, beach bags, accessories, and more, created in collaboration 
         with talented local artists."
                textTwo="At Aqualina, serenity and relaxation are always within reach. Whether you choose to 
        unwind on your private cabana, immerse yourself in a captivating book next to the pool, or 
        simply enjoy precious moments with friends on the beach, our philosophy of easy living allows 
        you to embrace the tranquility of island life."/>

            <ShopComingSoon
                caption1="We are"
                caption2="Coming"
                caption3="Soon!"
                logo={shoplogo}
                insta={instagram}
                facebook={facebook}
                tiktok={tiktok}
                whatsapp={whatsapp}
                mainImage={shopmainimg}
                tMark="© 2023 Aqualina Beach House.  All rights reserved"
                label="SHOP"
                buttonText="Notify me" />

            <ContactForm
                title="GET IN TOUCH"
                buttonText="Send Message" /> */}

        </>
    );
}