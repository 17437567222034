import { HeroComponent } from "../../Components/HeroComponent";
import React, { useEffect, useState } from "react";
import heroImage from "../../images/contactusimage.png"
import ContactForm from "../../Components/contact-form/contact-form";
import { SubscriberComponent } from "../../Components/SuscriberComponent";
import { FooterComponent } from "../../Components/FooterComponent";
import { ContactDetails } from "../../Components/ContactDetails";
import Layout from "../Common/page-layout";



export default function ContactUsPage() {

    const [heroContent, setHeroContent] = useState(
        {
            imageUrl: heroImage,
            title: "Contact Us",
        })

    return (
        <Layout heroProp={{ showSlide: false, slideContent: heroContent }}>
            <ContactForm
                title="GET IN TOUCH"
                buttonText="Send Message" />
            <ContactDetails />
        </Layout>


    );
}