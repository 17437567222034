import { Link } from "react-router-dom";
import { TextContainerProps } from "../Dtos/types/text-container";

export const TextContComponent = (props: TextContainerProps) => {
  let containerClassName = "md:flex md:flex-col justify-between bg-[#FFFFFF] text-[#3D3D3D] lg:w-[383px] md:w-[310px] w-[full] md:h-[360px] h-[auto] pt-[2.5rem] pb-[2.5rem] px-[10px] md:mb-[0] mb-[50px] lg:px-[40px] my-50 md:absolute z-[2]";
  let titleTextColor = "text-[#2789CA]";
  let buttonTextColor = "text-[#2789CA]";
  let buttonBgColor = "bg-white";
  let textColor = "text-[#505457]";


  if (props.darkMode === true && props.cardPosition === 'Right') {
      // Dark mode
      containerClassName = "md:flex md:flex-col justify-between bg-[#000000] text-[#3D3D3D] md:h-[360px] h-[auto] lg:w-[383px] md:w-[310px] h-[auto] pt-[2.5rem] pb-[2.5rem] px-[10px] md:mb-[0] mb-[50px] lg:px-[40px] my-50 md:absolute z-[2] md:left-[60%]";
      titleTextColor = "text-[#FFFFFF]";
      buttonTextColor = "text-[#000000]";
      buttonBgColor = "bg-[#FFFFFF]";
      textColor = "text-[#FFFFFF]";
  } else if (props.darkMode === true && props.cardPosition === 'Left') {
    // Dark mode
    containerClassName = "md:flex md:flex-col justify-between bg-[#000000] text-[#3D3D3D] md:h-[360px] h-[auto] lg:w-[383px] md:w-[310px] h-[auto] pt-[2.5rem] pb-[2.5rem] px-[10px] md:mb-[0] mb-[50px] lg:px-[40px] my-50 md:absolute z-[2] md:right-[60%]";
    titleTextColor = "text-[#FFFFFF]";
    buttonTextColor = "text-[#000000]";
    buttonBgColor = "bg-[#FFFFFF]";
    textColor = "text-[#FFFFFF]";
}else if (props.cardPosition === 'Left') {
    // Left position
    containerClassName += " md:right-[60%]";
    titleTextColor = "text-[#2789CA]";
    buttonTextColor = "text-[#2789CA]";
    buttonBgColor = "bg-white";
    textColor = "text-[#505457]";
  } else {
    // Default
    containerClassName += " md:left-[60%]";
    titleTextColor = "text-[#2789CA]";
    buttonTextColor = "text-[#2789CA]";
    buttonBgColor = "bg-white";
    textColor = "text-[#505457]";
  }

  return (
    <div data-aos="fade-up" data-aos-duration="2000" data-aos-easing="ease-in-out-cubic" className={containerClassName}>
      <p className={`lg:text-[40px] md:text-[30px] text-[40px] ${titleTextColor} font-['Dante_MT_Std'] md:pt-[0.5rem] font-400 leading-[42.4px] w-3/4`}>
        {props.title}
      </p>
      <p className={`lg:text-[14px] md:text-[12px] text-[16px] ${textColor} font-['Objektiv_Mk1_Trial'] font-400 md:leading-[18.9px] leading-[28.8px] w-full py-[15px] lg:pt-[1rem] lg:py-[1.2rem]`}>
        {props.text}
      </p>
      <Link  to={props.link} className={`w-[10rem] float-right text-center ${buttonTextColor} border border-[#2789CA] ${buttonBgColor} px-[24px] py-[0.625rem] rounded-[100px] text-14 font-500 leading-21 font-['Objektiv_Mk1_Trial']`}>
        {props.button}
      </Link>
    </div>
  );
};
