import React from 'react';
import logo from './logo.svg';
import './App.css';
import { Route, Routes } from 'react-router-dom';
import { AppRoute } from './route';
import { AqualinaFrontend } from './Pages/Sample/AqualinaFrontend';

function App() {
 
  return (

    <Routes>
     {AppRoute.map((route, index) => (
        <Route path={route.path} element={route.element}></Route>
     ))}
    </Routes>
   
  );
}

export default App;
