import React, { Component } from 'react'
import { HeroComponent } from '../../Components/HeroComponent'
import { ImageAndTextFullWidthComponent } from '../../Components/ImageAndTextFullWidthComponent'
import { StylizedTextComponent } from '../../Components/StylizedTextComponent'
import { SubscriberComponent } from '../../Components/SuscriberComponent'
import { FooterComponent } from '../../Components/FooterComponent'
import { LocationAndMapComponent } from '../../Components/LocationAndMapComponent'
import { BoxedCarouselComponent } from '../../Components/BoxedCarouselComponent'
import { SpotlightComponent } from '../../Components/SpotlightComponent'
import { ContactDetails } from '../../Components/ContactDetails'
import { MenuSectionComponent } from '../../Components/MenuSectionComponent'
import { VideoBoxComponent } from '../../Components/VideoBoxComponent'
import { BoxedCarouselProps } from "../../Dtos/types/boxed-carousel";
import { HeroProps } from '../../Dtos/types/hero';
import { MenuSectionProps } from '../../Dtos/types/menu-section';

const menu: MenuSectionProps = {
    menuHighlights: [
        {
            menuItems: [
                {
                    "details": "Whitefish | shrimp | rocotto pepper | avocado | cancha corn | sweet potato puree | flour tortilla | leche de tigre",
                    "title": "Ceviche Tacos  ( G )"
                },
                {
                    "details": "Whitefish | shrimp | rocotto pepper | avocado | cancha corn | sweet potato puree | flour tortilla | leche de tigre",
                    "title": "Fried Rice ( G )"
                },
                {
                    "details": "Whitefish | shrimp | rocotto pepper | avocado | cancha corn | sweet potato puree | flour tortilla | leche de tigre",
                    "title": "Salmon Poke Bowl ( G )"
                }
            ],
            menuTitle: "APPETIZERS"
        },
        {
            menuItems: [
                {
                    "details": "Whitefish | shrimp | rocotto pepper | avocado | cancha corn | sweet potato puree | flour tortilla | leche de tigre",
                    "title": "Ceviche Tacos  ( G )"
                },
                {
                    "details": "Whitefish | shrimp | rocotto pepper | avocado | cancha corn | sweet potato puree | flour tortilla | leche de tigre",
                    "title": "Fried Rice ( G )"
                },
                {
                    "details": "Whitefish | shrimp | rocotto pepper | avocado | cancha corn | sweet potato puree | flour tortilla | leche de tigre",
                    "title": "Salmon Poke Bowl ( G )"
                }
            ],
            menuTitle: "DESSERT"
        },
        {
            menuItems: [
                {
                    "details": "Whitefish | shrimp | rocotto pepper | avocado | cancha corn | sweet potato puree | flour tortilla | leche de tigre",
                    "title": "Ceviche Tacos  ( G )"
                },
                {
                    "details": "Whitefish | shrimp | rocotto pepper | avocado | cancha corn | sweet potato puree | flour tortilla | leche de tigre",
                    "title": "Fried Rice ( G )"
                },
                {
                    "details": "Whitefish | shrimp | rocotto pepper | avocado | cancha corn | sweet potato puree | flour tortilla | leche de tigre",
                    "title": "Salmon Poke Bowl ( G )"
                }
            ],
            menuTitle: "MAIN DISH"
        },
        {
            menuItems: [
                {
                    "details": "Whitefish | shrimp | rocotto pepper | avocado | cancha corn | sweet potato puree | flour tortilla | leche de tigre",
                    "title": "Ceviche Tacos  ( G )"
                },
                {
                    "details": "Whitefish | shrimp | rocotto pepper | avocado | cancha corn | sweet potato puree | flour tortilla | leche de tigre",
                    "title": "Fried Rice ( G )"
                },
                {
                    "details": "Whitefish | shrimp | rocotto pepper | avocado | cancha corn | sweet potato puree | flour tortilla | leche de tigre",
                    "title": "Salmon Poke Bowl ( G )"
                }
            ],
            menuTitle: "SALAD"
        },
        {
            menuItems: [
                {
                    "details": "Whitefish | shrimp | rocotto pepper | avocado | cancha corn | sweet potato puree | flour tortilla | leche de tigre",
                    "title": "Ceviche Tacos  ( G )"
                },
                {
                    "details": "Whitefish | shrimp | rocotto pepper | avocado | cancha corn | sweet potato puree | flour tortilla | leche de tigre",
                    "title": "Fried Rice ( G )"
                },
                {
                    "details": "Whitefish | shrimp | rocotto pepper | avocado | cancha corn | sweet potato puree | flour tortilla | leche de tigre",
                    "title": "Salmon Poke Bowl ( G )"
                }
            ],
            menuTitle: "SHARING PLATTER"
        },
        {
            menuItems: [
                {
                    "details": "Whitefish | shrimp | rocotto pepper | avocado | cancha corn | sweet potato puree | flour tortilla | leche de tigre",
                    "title": "Ceviche Tacos  ( G )"
                },
                {
                    "details": "Whitefish | shrimp | rocotto pepper | avocado | cancha corn | sweet potato puree | flour tortilla | leche de tigre",
                    "title": "Fried Rice ( G )"
                },
                {
                    "details": "Whitefish | shrimp | rocotto pepper | avocado | cancha corn | sweet potato puree | flour tortilla | leche de tigre",
                    "title": "Salmon Poke Bowl ( G )"
                }
            ],
            menuTitle: "SUSHI"
        }
    ],
    menuSummary: [
        "Dairy (D)",
        "Non-Alcoholic (NA)",
        "Vegetarian (V)",
        "Gluten (G)",
        "Seafood (S)",
        "Nuts (N)",
        "Alcoholic (A)",
        "Diabetic (DA)"
    ]

}

const slideHero: HeroProps = {

    slideContent: [
        {
            imageUrl: "/images/hero-section/about-hero.png",
            title: "Memorable days. Exceptional nights.",
            titleCursive: "nights"
        },
        {
            imageUrl: "/images/hero-section/explore-hero.png",
            title: "Memorable days. Exceptional nights.",
            titleCursive: "nights"
        }
    ],
    showSlide: true
}

const Hero: HeroProps = {

    slideContent: {
        imageUrl: "/images/hero-section/about-hero.png",
        title: "About Us",
        titleCursive: "",
    }   
        ,
    
    showSlide: false
}

const data: BoxedCarouselProps = {
    carouselContent: [
        {
            mediaUrl: '/images/video-img-section/interview-mission.png',
            title: 'Interviews',
            description: 'Interview with superstar ‘the weekend’ in Webley stadium',
            cardPosition: 'Left',
            link:"#"
        },

    ],
    imagePosition: '',
    darkMode: false,
    imageCarousel: false,
    title: '',
    button: '',
    bgColor: true
}

const slideData: BoxedCarouselProps = {
    carouselContent: [
        {
            mediaUrl: '/images/video-img-section/lina-mission.png',
            title: 'Lina Rooms',
            description: 'These Aqua rooms are double bedded and can accommodate up to three adults. Our Aqua Rooms offer the perfect balance of comfort, luxury and mesmerising views that will take your breath away.',
            cardPosition: 'Right',
            link: "#"
        },
        {
            mediaUrl: '/images/video-img-section/master-mission.png',
            title: 'Master Suites',
            description: 'These Aqua rooms are double bedded and can accommodate up to three adults. Our Aqua Rooms offer the perfect balance of comfort, luxury and mesmerising views that will take your breath away.',
            cardPosition: 'Right',
            link: "#"
        },

    ],
    imagePosition: '',
    darkMode: false,
    imageCarousel: false,
    title: '',
    button: '',
    bgColor: true
}

export const AqualinaFrontend = () => {
    return (
        <div>
            <HeroComponent
                slideContent={slideHero.slideContent}
                showSlide={true}
            />
            <HeroComponent
                slideContent={Hero.slideContent}
                showSlide={false}
            />
            <StylizedTextComponent text='Our mission is to create unique 
and lavish sensory experiences 
in the most idyllic beach locales.' focusText='sensory experiences' />
            <VideoBoxComponent 
                image='/images/video-img-section/club.png'
                playButton="/images/icons/playbutton.png"
                video='/videos/video.mp4'
                title='Mansa 
                   Beach Club'
                text='Mansa by Aqualina Beach House reinvents the effervescent character of beach-party lifestyle with an atmosphere that ignites undiscovered sensory experiences. We plan to expand across the African coast and select destinations around the world.'
                button='Coming Soon'
                cardPosition="Left"
                darkMode={false} link='#' />

            <ImageAndTextFullWidthComponent
                video={undefined}
                image='/images/video-img-section/about-img-only.png'
                playButton={undefined}
            />
            <StylizedTextComponent text='As the founder and owner of Aqualina Beach House, Femi Fowora is involved with every facet of the luxury, lifestyle brand.' focusText='' />
            <SubscriberComponent />
            <FooterComponent />
            <LocationAndMapComponent
                title='In this Location'
                map='/images/location-section/map.png'
                text='Aqualina Beach House Mansa Beach Club'
                timeZone='West Africa Standard Time: GMT+1'
                coordinate="25° 47' 26.354'' N 80° 7' 48.164'' W"
                logo='/images/location-section/aqua-mansa-logo.png'
            />
            <SpotlightComponent
                image='/images/people-section/founder.png'
                text="As the founder of this exclusive retreat, I have always lived by the belief that true fulfillment lies in savoring life's most exquisite moments. Inspired by my own lifestyle experiences and a deep appreciation for the finer things in life, I have meticulously crafted Aqualina Beach House to encompass refined elegance, tranquility, and unparalleled comfort. Aqualina embodies the spirit of my vision, capturing the essence of an extraordinary lifestyle. It is an invitation to immerse yourself in a world where every moment is a celebration of beauty, flavors, and cultural richness. I am delighted to share this exquisite brand with you and extend my warmest welcome to embark on a journey of unforgettable experiences."
                title='Life is the pursuit of happiness, and it is with great pleasure that I invite you to discover your own happiness at Aqualina.'
                designation='FOUNDER & OWNER, AQUALINA BEACH HOUSE'
                name='Femi Fowora'
            />
            <ContactDetails />
            <BoxedCarouselComponent
                title='EXPERIENCES'
                imagePosition='Left'
                darkMode={false}
                imageCarousel={true}
                carouselContent={slideData.carouselContent}
                button='Learn More'
                bgColor={false}
            />
            <BoxedCarouselComponent
                title='YOU MAY ALSO LIKES'
                imagePosition='Right'
                darkMode={true}
                imageCarousel={false}
                carouselContent={data.carouselContent}
                button='Learn More'
                bgColor={true}
            />
            <MenuSectionComponent
                menuHighlights={menu.menuHighlights}
                menuSummary={menu.menuSummary}
            />
        </div>

    )
}