import { useEffect, useState } from "react";
import { RoomsMainApiResponse } from "../../Dtos/rooms-main-api-response-data";
import { ApiManager } from "../../Utilities/api-manager";
import RoomCards from "../../Components/room-cards/room-cards";
import { StylizedTextComponent } from "../../Components/StylizedTextComponent";
import { SlideComponentItem } from "../../Dtos/types/hero";
import { BoxedCarouselComponent } from "../../Components/BoxedCarouselComponent";
import { CarouselComponentItem } from "../../Dtos/types/boxed-carousel";
import Layout from "../Common/page-layout";
import { Loader } from "../../Utilities/loader";


export default function RoomsMain() {

    const [roomsApiResponse, setRoomsApiResponse] = useState<RoomsMainApiResponse | null>(null);
    const [slideContents, setSlideContents] = useState<SlideComponentItem>({ imageUrl: "", title: "" });
    const [carouselContent, setCarouselContent] = useState<CarouselComponentItem[]>([]);
    const [pageLoading, setPageLoading] = useState(false)




    useEffect(() => {
        fetchInfo();
    }, []);

    const fetchInfo = async () => {
        setPageLoading(true)
        const response = await ApiManager<any, RoomsMainApiResponse>("/page/rooms", "GET");
        if (response) {
            let responseData = response.data as RoomsMainApiResponse;
            let heroData: SlideComponentItem = {
                imageUrl: responseData.HeroSection?.imageUrl as string,
                title: responseData.HeroSection?.title as string,
                titleCursive: responseData.HeroSection?.titleCursive,
            };
            let carouselData: CarouselComponentItem[] = [];
            responseData.ExperienceSection?.forEach(content => {
                carouselData.push({
                    cardPosition: content.cardPosition as string,
                    description: content.description as string,
                    mediaUrl: content.mediaUrl as string,
                    title: content.title as string,
                    link: content.link as string
                });
            });
            setSlideContents(heroData);
            setRoomsApiResponse(response.data as RoomsMainApiResponse);
            setCarouselContent(carouselData);
            setPageLoading(false);
        } else {
            setPageLoading(false);
        }
    }

    return (
        <>
            {
                roomsApiResponse ?
                    <Layout heroProp={{ showSlide: false, slideContent: slideContents as SlideComponentItem }}>
                        <StylizedTextComponent
                            text={roomsApiResponse.CursiveContentSection?.content as string}
                            focusText={roomsApiResponse.CursiveContentSection?.contentCursive as string}
                        />

                        <RoomCards
                            title="ALL ROOMS AND SUITES"
                            cardArray={roomsApiResponse.RoomsSection}
                            buttonText="Discover More" />

                        <BoxedCarouselComponent
                            carouselContent={carouselContent}
                            title="EXPERIENCES"
                            bgColor={false}
                            button="Learn More"
                            imagePosition=""
                            darkMode={false}
                            imageCarousel={true}
                        />
                    </Layout> : <Loader />


            }
        </>
    );
}