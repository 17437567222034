import { LocationCardProps } from "../Dtos/types/location-card";

export default function AboutLocation(props:LocationCardProps) {
    
    return (
        <div className="">
            <div>
                <h1 className="font-['Objektiv_Mk1_Trial] font-[700] text-[1rem] text-[#505457] leading-[1.59375rem] text-center flex items-center justify-center md:pb-[2rem] pb-[1rem]">{props.caption}</h1>
            </div>
            <div className="about-one-web">
                <div className="grid grid-cols-2">
                    <div className="">
                        <img className="w-full"  src={props.image} />
                    </div>
                    <div className="about-us-text-div">
                        <p data-aos="fade-left" className="font-['Objektiv_Mk1_Trial'] text-[#505457] about-us-text">{props.text}</p>
                    </div>
                </div>
            </div>

            <div className="about-one-mobile px-5 mb-0">
                <img className="about-us-image pt-5" src={props.image} />
                <p className="about-us-text pt-5 font-['Objektiv_Mk1_Trial'] font-[400] text-[1rem] text-[#505457]">{props.text}</p>
            </div>
        </div>
    );
}