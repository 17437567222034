import { useState, useEffect } from 'react'
import { ApiManager } from '../../Utilities/api-manager';
import OurLocation from '../../Components/our-location/our-location';
import locationImage from "../../images/location image.jpeg";
import locationimageMobile from "../../images/locationmobile.jpeg";
import playImage from "../../images/play.png";
import { StylizedTextComponent } from '../../Components/StylizedTextComponent';
import WelcomeToAqualina from '../../Components/welcome-to-aqualina/welcome-to-aqualina';
import welcomeimagetwomobile from "../../images/welcomeimage2mobile.png";
import welcomeimageonemobile from "../../images/ladyhatmobile.png";
import RayWaves from '../../Components/ray-waves/ray-waves';
import JoinAfrica from '../../Components/join-africa/join-africa';
import backgroundImage from "../../images/Rectangle75.png";
import { FullWidthGalleryCarouselComponent } from '../../Components/full-width-gallery-carousel/full-width-gallery-carousel';
import { VideoBoxComponent } from '../../Components/VideoBoxComponent';
import { BoxedCarouselComponent } from '../../Components/BoxedCarouselComponent';
import Layout from '../Common/page-layout';
import { Loader } from '../../Utilities/loader';
import { FullHorizontalCarouselComponent } from '../../Components/full-horizontal-carousel-component/full-horizontal-carousel-component';
import { HomeResponse } from '../../Dtos/ApiResponse/home-response';
import { FullHorizontalCarouselComponentItem } from '../../Dtos/full-horizontal-carousel-component-prop';


export function HomePage() {
  const [responseData, setResponseData] = useState<HomeResponse | null>(null);
  const[suiteCarousel, setSuiteCarousel] = useState<FullHorizontalCarouselComponentItem[]>([])

  useEffect(() => {
    const fetchData = async () => {
      const response = await ApiManager<any, HomeResponse>('/page/home', 'GET');
      if (response) {
        let _responseData = response.data as HomeResponse;
        let carousel: FullHorizontalCarouselComponentItem[] = [];
        _responseData.RoomsAndSuiteSection.forEach(x=> {
          carousel.push({
            image: x.imageUrl,
            route: `/room-details?title=${x.title}`,
            title: x.title
          });
        });
        _responseData.ShopSection[0].link = "/shop"
        setResponseData(_responseData);
        setSuiteCarousel(carousel);
        console.log(response?.data);
      }

    };

    fetchData();
  }, []);

  return (
    <>
      {
        responseData ?
          <Layout heroProp={{ showSlide: true, slideContent: responseData.HeroSection }}>
            <OurLocation
              title="OUR LOCATION"
              caption={responseData.LocationSection.videoTitle}
              text={responseData.LocationSection.videoDescription}
              buttontext="Discover More"
              image={locationImage}
              locationPlay={playImage}
              mobileimage={locationimageMobile}
              link="/experience"
              video={responseData.LocationSection.videoUrl}
            />
            <StylizedTextComponent text={responseData.OurMissionSection.content} focusText={responseData.OurMissionSection.contentCursive} />
            <WelcomeToAqualina
              link="/experience"
              title="WELCOME TO AQUALINA"
              caption={responseData.WelcomeSection.title}
              imageOne={responseData.WelcomeSection.leftImageUrl}
              mobileimageOne={welcomeimageonemobile}
              imageTwo={responseData.WelcomeSection.rightImageUrl}
              mobileimageTwo={welcomeimagetwomobile}
              text={responseData.WelcomeSection.content}
              buttontext="Learn More"
            />
            <FullHorizontalCarouselComponent
            carouselContent={suiteCarousel}
            title="ROOMS & SUITES"
            />
            <BoxedCarouselComponent
              title='EXPERIENCES'
              imagePosition='Left'
              darkMode={false}
              imageCarousel={true}
              carouselContent={responseData.ExperienceSection}
              button='Learn More'
              bgColor={false}
            />
            <VideoBoxComponent
              image='/images/video-img-section/club.png'
              playButton="/images/icons/playbutton.png"
              video={responseData.MansaBeachSection.mediaUrl}
              title={responseData.MansaBeachSection.title}
              text={responseData.MansaBeachSection.description}
              button='Coming Soon'
              cardPosition={responseData.MansaBeachSection.cardPosition}
              darkMode={false}
              link='#'
            />
            <StylizedTextComponent text={responseData.DevotionSection.content} focusText={responseData.DevotionSection.contentCursive} />
            <JoinAfrica
              backgroundimage={backgroundImage}
              title="MEMBERSHIP"
              buttontext="Apply for Aqualina Membership"
              bodytext={responseData.MembershipSection.content}
              altBodyText=""
              link="/"
            />
            <FullWidthGalleryCarouselComponent
              images={responseData.GallerySection.images}
              title={responseData.GallerySection.title}
              route=""
              routeLabel="Explore Gallery"
            />
            <BoxedCarouselComponent
              title='SHOP'
              imagePosition='Left'
              darkMode={true}
              imageCarousel={false}
              carouselContent={responseData.ShopSection}
              button='View Shop'
              bgColor={false}
            />
            <RayWaves
              link="/"
              title="RAYS & WAVES"
              bodytext={responseData.RaysAndWavesSection.content}
              textaccent={responseData.RaysAndWavesSection.contentCursive}
              buttontext="Explore Our Blog"
              backgroundimage={responseData.RaysAndWavesSection.imageUrl}
              track="Beauty Behind The Madne..."
              artiste="The Weekend"
              sportifyimage={responseData.RaysAndWavesSection.spotifyUrl}
              seeplaylist="See Playlist"
            />
          </Layout> : <Loader />
      }
    </>
  ) 
}