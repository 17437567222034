import React, { useEffect, useState } from "react";
import "./ray-waves.css"
import { RayWavesProp } from "../../Dtos/types/ray-waves-interface";
import pause from "../../images/pause.png";
import back from "../../images/back.png";
import forward from "../../images/forward.png";
import spotifylogo from "../../images/spotifylogo.png"
import AOS from 'aos';
import 'aos/dist/aos.css';
import { Link } from "react-router-dom";



export default function RayWaves(props:RayWavesProp) {

    useEffect(() => {
        AOS.init();
      }, [])
    
      const textArray = props.bodytext.split(props.textaccent);
      console.log(textArray);
      
      

    return (
        <>
        <div style={{background: `url(${props.backgroundimage})`}} className="pb-10 raywaves">
            <div className="mb-10">
                <p data-aos="fade-up" className="join-africa-title text-center">{props.title}</p>
            </div>
            <div data-aos="fade-up" className="raywaves-caption-div">
                <p className="raywaves-caption">
                {textArray.map((text, index) => (
                <>
                {text}
                {index < textArray.length - 1 && (
                    <span style={{marginLeft:"10px"}} className="raywaves-caption-accent">
                       {props.textaccent}
                    </span>
                )}
                </>))}
                </p>
            </div>
            <div className="mt-10 butandspotify">
                <div className="ray-wave-butdiv">
                    <Link to={props.link}>
                        <button data-aos="fade-up" type="button" className="rounded-full bg-white px-10 py-4 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 ray-waves-buttext">{props.buttontext}</button>
                    </Link>
                </div>
                <div className="spotify-div px-5 py-5 rounded-md">
                    <div className="ray-track-section">
                        <img src={props.sportifyimage} alt="" />
                        <div className="ml-3">
                            <p className="spottrack">{props.track}</p>
                            <p className="spotartiste">{props.artiste}</p>
                        </div>
                    </div>
                    <div className="playcontrol mt-5">
                        <img className="back" src={back} alt=""/>
                        <img className="pause mr-5 ml-5" src={pause} alt=""/>
                        <img className="forward" src={forward} alt=""/>
                    </div>
                    <div className="spotify-lower-section mt-5">
                        <button type="button" className="rounded-full bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 open-spotify"><img style={{marginRight:"4px"}} src={spotifylogo} alt="" />Open Spotify</button>
                        <p className="see-playlist">{props.seeplaylist}</p>
                    </div>
                </div>
            </div>
        </div>
        </>
    );
}