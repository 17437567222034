import React, { useEffect, useState } from "react";
import "./contact-form.css";
import AOS from 'aos';
import 'aos/dist/aos.css';
import { ContactFormProps } from "../../Dtos/types/contact-form-interface";
import { PhoneInput } from 'react-international-phone';
import 'react-international-phone/style.css';
import { ApiManager } from "../../Utilities/api-manager";
import fire from "../../Utilities/alert";
import { MessageType } from "../../Enums/message-type";
import axios from "axios";
import { Loader } from "../../Utilities/loader";


export default function ContactForm(props:ContactFormProps) {

    const [payload, setPayload] = useState({
        firstName:"", lastName:"", email:"", message:"", phone:"" 
    });
    const [phone, setPhone] = useState("");
    const [defaultCountry, setDefaultCountry] = useState("");
    const [loading, setLoading]  = useState<Boolean>(false);
    
    useEffect(() => {
        AOS.init();
        async function fetchCountry(){
            const response = await axios.get('https://ipapi.co/json/');
            console.log({countries:response.data});
            let dummy:string = response.data.country_code;
            console.log({dummy:dummy});
            setDefaultCountry(dummy.toLowerCase());
            console.log({defaultCountry:defaultCountry});  
        }
         fetchCountry();
        
      }, [])

  

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const { name, value } = event.target;
        setPayload({
          ...payload,
          [name]: value,
        });
      };

    

     const submitForm=async()=>{
        setLoading(true);
        let request:any = {
            ...payload, phone:phone
        }
        
        const response = await ApiManager<any, any>("/page/contact", "POST", request);
        console.log({response:response});
        
        if(response){
            fire(
                MessageType.success,
                response.message
            )
            setLoading(false);
            setTimeout(() => {
                window.location.reload();
            }, 2000);
        }else{
            setLoading(false);
        }

     }

    // methods
    
    return (defaultCountry?
        <>
        <div className="contact-form">
            <div  className="contact-form-web pt-20">
                <div>
                    <p className="text-center mb-14 contact-form-title">{props.title}</p>
                </div>
                <div className="grid grid-cols-2 gap-14">
                    <div>
                        <label htmlFor="text" className="block text-sm font-medium leading-6 text-gray-900 contact-form-label">
                        First name*
                        </label>
                        <div className="mt-2 mb-5">
                            <input type="text" name="firstName" id="firstName"
                                className="block w-full rounded-md border-0 py-3 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-grey-600 sm:text-sm sm:leading-6"
                                placeholder="Enter first name"
                                value={payload.firstName}
                                onChange={handleInputChange}/>
                        </div>
                        <label htmlFor="phone" className="block text-sm font-medium leading-6 text-gray-900 contact-form-label">
                        Phone number*
                        </label>
                        <div className="mt-2 mb-5">
                            <PhoneInput
                            defaultCountry={defaultCountry}
                            value={payload.phone}
                            onChange={(phone)=> setPhone(phone)}
                            />
                        </div>

                    </div>
                    <div>
                        <label htmlFor="text" className="block text-sm font-medium leading-6 text-gray-900 contact-form-label">
                        Last name*
                        </label>
                        <div className="mt-2 mb-5">
                            <input type="text" name="lastName" id="lastName"
                                className="block w-full rounded-md border-0 py-3 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-grey-600 sm:text-sm sm:leading-6"
                                placeholder="Enter last name"
                                value={payload.lastName}
                                onChange={handleInputChange}/>
                        </div>
                        <label htmlFor="email" className="block text-sm font-medium leading-6 text-gray-900 contact-form-label">
                        Email address*
                        </label>
                        <div className="mt-2 mb-5">
                            <input type="email" name="email" id="email"
                                className="block w-full rounded-md border-0 py-3 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-grey-600 sm:text-sm sm:leading-6"
                                placeholder="Enter email address"
                                value={payload.email}
                                onChange={handleInputChange}/>
                        </div>

                    </div>
                </div>
                <div className="mb-10">
                    <label htmlFor="comment" className="block text-sm font-medium leading-6 text-gray-900 contact-form-label">
                    Add your comment
                    </label>
                    <div className="mt-2">
                        <textarea rows={4} name="message" id="comment" placeholder="Write a message..."
                        className="block w-full rounded-md border-0 py-1.5 pl-3 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-grey-600 sm:text-sm sm:leading-6"
                        onChange={handleInputChange}
                        value={payload.message}/>
                    </div>
                </div>
                <div className="mb-20 text-center">
                    {
                        loading?
                        <Loader/>
                        :
                        <button type="button"
                        className=" pl-20 pr-20 pt-4 pb-4 rounded-md bg-indigo-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-#025185-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 contact-form-button"
                        onClick={()=>submitForm()}>
                        {props.buttonText}
                    </button>

                    } 
                    
                </div>

            </div>

            {/* mobile view */}
            <div className="contact-form-mobile pl-5 pr-5 pt-20">
                <div>
                    <p className="text-center mb-14 contact-form-title">{props.title}</p>
                </div>
                <label htmlFor="text" className="block text-sm font-medium leading-6 text-gray-900 contact-form-label">
                        First name*
                </label>
                <div className="mt-2 mb-5">
                    <input type="text" name="firstName" id="firstName"
                    className="block w-full rounded-md border-0 py-3 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-grey-600 sm:text-sm sm:leading-6"
                    placeholder="Enter first name"
                    value={payload.firstName}
                    onChange={handleInputChange}/>
                </div>
                <label htmlFor="text" className="block text-sm font-medium leading-6 text-gray-900 contact-form-label">
                        Last name*
                </label>
                <div className="mt-2 mb-5">
                    <input type="text" name="lastName" id="lastName"
                    className="block w-full rounded-md border-0 py-3 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-grey-600 sm:text-sm sm:leading-6"
                    placeholder="Enter last name"
                    value={payload.lastName}
                    onChange={handleInputChange}/>
                </div>
                <label htmlFor="phone" className="block text-sm font-medium leading-6 text-gray-900 contact-form-label">
                        Phone number*
                </label>
                <div className="mt-2 mb-5">
                    <PhoneInput
                        defaultCountry="ua"
                        value={payload.phone}
                        onChange={(phone)=> setPhone(phone)}
                        />
                </div>
                <label htmlFor="email" className="block text-sm font-medium leading-6 text-gray-900 contact-form-label">
                        Email address*
                </label>
                <div className="mt-2 mb-5">
                    <input type="email" name="email" id="email"
                        className="block w-full rounded-md border-0 py-3 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-grey-600 sm:text-sm sm:leading-6"
                        placeholder="Enter email address"
                        value={payload.email}
                        onChange={handleInputChange}/>
                </div>
                <div className="mb-10">
                    <label htmlFor="comment" className="block text-sm font-medium leading-6 text-gray-900 contact-form-label">
                    Add your comment
                    </label>
                    <div className="mt-2">
                        <textarea rows={4} name="message" id="message" placeholder="Write a message..."
                        className="block w-full rounded-md border-0 py-1.5 pl-3 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-grey-600 sm:text-sm sm:leading-6"
                        value={payload.message}
                        onChange={handleInputChange}/>
                    </div>
                </div>
                <div className="mb-20 text-center"> 
                {
                     loading?
                     <Loader/>
                     :
                     <button type="button"
                        className=" pl-20 pr-20 pt-4 pb-4 rounded-md bg-indigo-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-#025185-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 contact-form-button"
                        onClick={()=>submitForm()}>
                        {props.buttonText}
                    </button>
                }
                    
                </div>
                
            </div>
            {/* mobile view */}

        </div>
        </>:
        <></>

    );
}