const navigation = [
    { name: 'Home', href: '/' },
    { name: 'About Us', href: '/about-us' },
    { name: 'Rooms & Suites', href: '/rooms' },
    { name: 'Dine + Drink', href: '/dine-and-drink' },
    { name: 'Experiences', href: '/experience' },
    { name: 'Gallery', href: '#' },
    { name: 'Private Events', href: '#' },
    { name: 'Event Calendar', href: '#' },
    { name: 'Shop', href: '/shop' },
    { name: 'Contact Us', href: '/contact-us' },
    { name: 'Blog', href: '#' },
]

type MenuProps = {
    onSignInClick: () => void;
    onExploreMembershipClick: () => void;
    menuOpen: boolean;
    onClose: () => void;
    background: string;
};

export const MenuComponent = (props: MenuProps) => {
    return (
        <div className="w-full">
            <div className={props.menuOpen ? "menu-open" : "menu-closed"}>
                {props.menuOpen && (
                    <div className="w-full object-cover bg-cover text-center">
                        <div className="h-full flex justify-center items-center">
                            <div className="flex flex-wrap flex-col md:flex-row justify-center">
                                {navigation.map((item, index) => (
                                    <div key={item.name} className="mx-20 lg:my-[1.5rem] md:my-[1.8rem] my-[0.5rem] max-[360px]:my-0" data-aos="fade-up" data-aos-duration="2000" data-aos-easing="ease-in-out-cubic">
                                        <a href={item.href} className="font-['Dante_MT_Std'] lg:text-[56px] text-[32px] font-[700] text-[#FFFFFF] hover:underline leading-[48px] md:leading-[84px]">
                                            {item.name}
                                        </a>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};
