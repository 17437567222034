import { useEffect, useState } from "react";
import { PartnerComponentProp } from "../../Dtos/partner-component-prop";

export const PartnerComponent:React.FC<PartnerComponentProp> = ({images}) => {
   const [imageCount, setImageCount] = useState(0);
   useEffect(() => {
    setImageCount(images.length);
  }, [])
    return (
        <section className="partner-section bg-[#EEF6FB] pt-5 pb-20">
            <h4 className="partner-title font-Objektiv text-base font-semibold text-[#505457] text-center mb-10">OUR PARTNERS</h4>
         <div className="container mx-auto px-5">
            <div className={`grid grid-cols-${imageCount} grid-flow-col gap-4 max-sm:grid-cols-2 max-sm:auto-rows-auto max-sm:grid-flow-row`}>
            {
                images.map((image, index) => (
                    <div className="partner-image-item flex text-center items-center justify-center">
                        <img src={image} className="max-w-full" />
                    </div>
                ))
            }
            </div>
         </div>
        </section>
    )
}