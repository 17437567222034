import envConfig from '../env-config.json';

export interface EnvConfig {
    SkipPreflightCheck: boolean;
    ApiRequestTimeout: number;
    BaseUrl: string;
    AppName: string;
    token:string;
    isLocal:boolean;
}

export const env = envConfig as EnvConfig;