import { FullHorizontalCarouselComponentProp } from "../../Dtos/full-horizontal-carousel-component-prop";
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/navigation';
import './full-horizontal-carousel-component.css'
import { Link } from "react-router-dom";


export const FullHorizontalCarouselComponent: React.FC<FullHorizontalCarouselComponentProp> = ({ title, carouselContent }) => {

  return (
    <section className="relative isolate overflow-hidden bg-white md:h-auto xs:h-[500px]">
      <div className="carouselTitle absolute m-auto text-center md:block z-10 inset-x-[30%] md:top-[19.5%] md:bottom-[65.5%] max-sm:top-[10%] max-sm:bottom-[81%] ">
        <h3 className=" text-white font-Objektiv uppercase text-xs font-semibold tracking-wider"> {title}</h3>
      </div>
      <Swiper navigation={true} modules={[Navigation]} className="mySwiper">
        {
          carouselContent.map((data) => (
            <SwiperSlide>
              <img src={data.image} className="md:w-full xs:object-cover max-sm:h-[500px]" alt="" />
              <div className="hero-content absolute m-auto xs:inset-y-[25%] inset-y-[40%] inset-x-[15%] pt-10 text-center text-white md:block z-50">
                <h2 className="font-extralight max-sm:text-4xl font-Dante_MT text-white mt-5 max-sm:mt-0 text-[2.9rem] tracking-wide">
                  <span className="left-dash md:hidden xs:inline-block h-1 w-14 bg-white absolute inset-x-[-15%] inset-y-[25%]"></span>
                  {data.title}
                  <span className="right-dash md:hidden xs:inline-block h-1 w-14 bg-white absolute inset-x-[90%] inset-y-[25%] ml-4"></span></h2>
                <div className="heroAction pt-20">
                  <Link className="rounded-full bg-white border-2 max-sm:border-0 border-[#2789CA] px-3.5 py-2 text-sm font-semibold text-[#2789CA] shadow-sm hover:bg-[#2789CA] hover:text-white hover:border-white focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-[#2789CA]" to={data.route}>Discover More</Link>
                </div>
              </div>

            </SwiperSlide>
          ))
        }
      </Swiper>

    </section>

  )

}