import { Link } from "react-router-dom";
import { FounderProps } from "../Dtos/types/founder";

export const SpotlightComponent = (props: FounderProps) => {
    const textParts = props.designation.split(',');
    const firstPart = textParts[0];
    const restText = textParts.slice(1).join(',');
    return (
        <div className="lg:mx-[5rem]">
            <div className="lg:flex flex-wrap items-center">
                <img src={props.image} className="w-full lg:w-1/2 lg:mb-0" alt="founder-image" />
                <div className="bg-[#2789CA]/[.08] p-[2rem] lg:p-[3rem] xl:mt-[10rem] lg:mt-[1rem] lg:w-1/2">
                    <h1 className="text-[#505457] font-[400] lg:text-[2.25rem] text-[1.75rem] leading-[1.925rem] lg:leading-[2.7rem] font-['Dante_MT_Std'] pb-2 lg:pb-4">
                        {props.title}
                    </h1>
                    <p className="text-[#505457] font-[400] leading-[1.8rem] lg:leading-[2.25rem] mb-[2rem] pb-[1rem] text-[1rem] lg:text-[1.125rem] lg:text-base font-['Objektiv_Mk1_Trial']">
                        {props.text}
                    </p>
                    <div className="py-2 lg:py-4 flex justify-end">
                        <p className="text-[#000000] font-[400] text-[0.875rem] lg:leading-[1.4875rem] text-[0.75rem] lg:text-base leading-[1.275rem] font-['Objektiv_Mk1_Trial']">
                            <span className="text-[#000000] font-[400] text-[1.625rem] leading-[3rem]  lg:leading-[3.25rem] text-[1.5rem] font-['Amalfi_Coast']">{props.name}</span><br />
                            {firstPart},<br />{restText}
                        </p>
                    </div>
                </div>
            </div>
            <div className="flex items-center justify-center">
                <Link to={"/our-teams"} className="my-[2rem] text-[#2789CA] px-4 lg:py-[0.625rem] lg:px-[1.5rem] my-4 lg:my-8 py-2 lg:py-4 rounded-full border-[#2789CA] border-2">Meet our Team</Link>
            </div>
        </div>
    )
}

