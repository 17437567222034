import React, { useEffect, useState } from "react";
import { LocationProp } from "../../Dtos/types/location-prop-interface";
import "./our-location.css"
import AOS from 'aos';
import 'aos/dist/aos.css';
import { Link } from "react-router-dom";
import { set } from "lodash";



export default function OurLocation(props:LocationProp) {

    const [modalOpen, setModalOpen]=useState(false);
    const [hideVideo, setHideVideo]=useState(true);
    const [hideOthers, setHideOthers]=useState(false);

    useEffect(() => {
        AOS.init();
      }, []);

    const openVideo = ()=>{
        let vid: any = document.getElementById("myVideo");
        setHideVideo(false);
        setHideOthers(true);
        vid.play();
    }
    
    const closeVideo = ()=>{
        let vid: any = document.getElementById("myVideo");
        setHideVideo(true);
        setHideOthers(false);
        vid.pause();
    }
    
    return (
        <>
        <div className="text-center pt-10 pb-10 pr-2 pl-2">
            <div className="location">
            <div>
                <p className="mb-4 title">{props.title}</p>
            </div>
            <div>
                <div style={{position:"relative"}} hidden={hideOthers}>
                    <img className="location-image" src={props.image} alt="" />
                    <img className="location-imagemobile" src={props.mobileimage} alt="" />
                    <div>
                        <img onClick={()=>openVideo()} typeof="button" className="location-play" src={props.locationPlay}/>
                    </div>
                </div>
            </div>
            <div hidden={hideOthers} className="captionandtext mb-6">
                <p data-aos="fade-up" className="caption">{props.caption}</p>
                <p data-aos="fade-up" className="text">{props.text}</p>
            </div>
            <div hidden={hideOthers}>
                <Link to={props.link}>
                    <button type="button" className="rounded-full bg-white px-4 py-2.5 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 buttext">
                    {props.buttontext}
                    </button>
                </Link>
            </div>
            </div>
                <div data-aos="fade-up" className="location-image-vidsect" hidden={hideVideo}>
                    <video id="myVideo" src={props.video} controls autoPlay={true} muted />
                    <button onClick={()=>closeVideo()} className="location-vid-close"><strong>X</strong></button>
                </div>
        </div>
        </>
    );
}