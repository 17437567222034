import { useState } from 'react'
import { ChevronDownIcon } from '@heroicons/react/20/solid'
import { Link } from 'react-router-dom';

const navigation = [
    // { url: '/images/footer-section/fb.png', href: '#' },
    { url: '/images/footer-section/twitter.png', href: '#' },
    { url: '/images/footer-section/whatsapp.png', href: '#' },
    { url: '/images/footer-section/tiktok.png', href: '#' },
]

export const FooterComponent = () => {
    const [selectedDropdown, setSelectedDropdown] = useState<string | null>(null);

    const toggleDropdown = (dropdown: string) => {
        if (selectedDropdown === dropdown) {
            setSelectedDropdown(null);
        } else {
            setSelectedDropdown(dropdown);
        }
    };
    return (
        <div className="text-[#2789CA] font-['Objektiv_Mk1_Trial'] w-full md:pt-[40px] md:h-404 ">
            <div className="md:flex md:justify-between justify-center min-[768px]:pb-[2rem] items-center w-full">
                <div className="1/4 flex items-center justify-center lg:ml-[3rem] lg:mr-[5rem] md:mr-[2rem] max-[768px]:hidden">
                    <img
                        src='/images/footer-section/logo-blue.svg'
                        alt="logo-blue"
                        className="max-w-full w-1/2 md:w-full"
                    />
                </div>
                <div className="1/4 flex items-center justify-center ml-[15px] py-[2rem] min-[768px]:hidden">
                    <img
                        src='/images/footer-section/logo-mobile.svg'
                        alt="logo-mobile"
                        className="max-w-full w-1/2 md:w-full"
                    />
                </div>
                <div className="w-full pl-10">
                    <table className="table-auto text-left md:max-w-full h-full w-full max-[768px]:hidden">
                        <thead className="font-[700] min-[860px]:text-[14px] text-[12px] leading-[14.42px]">
                            <tr>
                                <th className="py-[10px]">WHAT’S ON</th>
                                <th>MEMBERSHIP</th>
                                <th>QUICK LINKS</th>
                                <th>GENERAL</th>
                                <th>TERMS & CONDITIONS</th>
                            </tr>
                        </thead>

                        <tbody className="min-[860px]:text-[14px] text-[12px] leading-[17.08px] font-[400] cursor-pointer">
                            <tr>
                                <td className="py-[10px]"><a href='#'>Rays & Waves Blog</a></td>
                                <td> <a href='#'>Explore Memberships</a></td>
                                <td><a href='/about-us'>About Us</a></td>
                                <td><a href='#'>Location</a></td>
                                <td><a href='#'>Aqualina Sitemap</a></td>
                            </tr>
                            <tr>
                                <td className="py-[10px]"><a href='#'>Gallery</a></td>
                                <td><a href='#'>Member Log-in</a></td>
                                <td><a href='/rooms'>Rooms & Suites</a></td>
                                <td><a href='/contact-us'>Contact Us</a></td>
                                <td><a href='#'>Website Terms</a></td>
                            </tr>
                            <tr>
                                <td className="py-[10px]"><a href='/shop'>Shop</a></td>
                                <td> <a href='#'>Club Rules</a></td>
                                <td> <a href='/experience'>Experiences</a></td>
                                <td><a href='#'>FAQs</a></td>
                                <td><a href='#'>Privacy Policy</a></td>
                            </tr>
                            <tr>
                                <td className="py-[10px]"><a href='#'>Our Packages</a></td>
                                <td><a href='#'></a></td>
                                <td className=" pt-[10px] pb-[20px]"><a href='#'>Private Events</a></td>
                                <td><a href='#'>Careers</a></td>
                            </tr>
                            <tr>
                                <td><a href='#'></a></td>
                                <td><a href='#'></a></td>
                                <td><a href='#'>Event Calendar</a></td>

                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>

            <hr className="border-[#2789CA] border mx-[1.5rem] lg:mx-[3rem]" />

            <div className='min-[768px]:hidden w-full'>
                {['whats-on', 'membership', 'quick-links', 'general', 'terms-conditions'].map((item, index) => (
                    <div key={index}>
                        <div className='flex justify-between p-[1.5rem] items-center' onClick={() => toggleDropdown(item)}>
                            <p className="font-[700] text-[14px] leading-[14.42px]">
                                {item === 'whats-on' && 'WHAT’S ON'}
                                {item === 'membership' && 'MEMBERSHIP'}
                                {item === 'quick-links' && 'QUICK LINKS'}
                                {item === 'general' && 'GENERAL'}
                                {item === 'terms-conditions' && 'TERMS & CONDITIONS'}
                            </p>
                            <div className="ml-2">
                                <ChevronDownIcon className={`h-5 w-5 text-[#2789CA] cursor-pointer ${item === selectedDropdown ? 'transform rotate-180' : ''}`} aria-hidden="true" />
                            </div>
                        </div>
                        {item === selectedDropdown && (
                            <div className="min-[860px]:text-[14px] text-[14px] text-left px-[1.5rem] leading-[17.08px] font-[400] cursor-pointer">
                                <ul>
                                    {item === 'whats-on' && (
                                        <>
                                            <li className="py-[10px]"><a href='#'>Rays & Waves Blog</a></li>
                                            <li className="py-[10px]"><a href='#'>Gallery</a></li>
                                            <li className="py-[10px]"><a href='/shop'>Shop</a></li>
                                            <li className="py-[10px]"><a href='#'>Our Packages</a></li>
                                        </>
                                    )}
                                    {item === 'membership' && (
                                        <>
                                            <li className="py-[10px]"><a href='#'>Explore Memberships</a></li>
                                            <li className="py-[10px]"><a href='#'>Member Log-in</a></li>
                                            <li className="py-[10px]"><a href='#'>Club Rules</a></li>
                                        </>
                                    )}
                                    {item === 'quick-links' && (
                                        <>
                                            <li className="py-[10px]"><a href='/about-us'>About Us</a></li>
                                            <li className="py-[10px]"><a href='/rooms'>Rooms & Suites</a></li>
                                            <li className="py-[10px]"><a href='/experience'>Experiences</a></li>
                                            <li className="py-[10px]"><a href='#'>Private Events</a></li>
                                            <li className="py-[10px]"><a href='#'>Event Calendar</a></li>
                                        </>
                                    )}
                                    {item === 'general' && (
                                        <>
                                            <li className="py-[10px]"><a href='#'>Location</a></li>
                                            <li className="py-[10px]"><a href='/contact-us'>Contact Us</a></li>
                                            <li className="py-[10px]"><a href='#'>FAQs</a></li>
                                            <li className="py-[10px]"><a href='#'>Careers</a></li>
                                        </>
                                    )}
                                    {item === 'terms-conditions' && (
                                        <>
                                            <li className="py-[10px]"><a href='#'>Aqualina Sitemap</a></li>
                                            <li className="py-[10px]"><a href='#'>Website Terms</a></li>
                                            <li className="py-[10px]"><a href='#'>Privacy Policy</a></li>
                                        </>
                                    )}
                                </ul>
                            </div>
                        )}
                        <hr className="border-[#2789CA] border mx-[1.5rem]" />

                    </div>
                ))}
            </div>

            <div className="md:flex md:justify-between items-center justify-center flex-row">
                <div className="flex lg:px-[3rem] p-[1.5rem] cursor-pointer">
                    <a href='#'>
                        <img src='/images/footer-section/fb.png' alt="fb-icon" className="w-26 h-26 rounded-md border border-[#2789CA] py-1 px-[7px] mr-2 hover:-translate-y-px" />
                    </a>
                    {/* <img src='/images/footer-section/twitter.png' alt="twitter-icon" className="w-26 h-26 rounded-md border border-[#2789CA] p-1 mr-2 hover:-translate-y-px" />
                    <img src='/images/footer-section/whatsapp.png' alt="whatsapp-icon" className="w-26 h-26 rounded-md border border-[#2789CA] p-1 mr-2 hover:-translate-y-px" />
                    <img src='/images/footer-section/tiktok.png' alt="tiktok-icon" className="w-26 h-26 rounded-md border border-[#2789CA] p-1 hover:-translate-y-px" /> */}
                    {navigation.map((item, index) => (
                        <div key={item.url}>
                            <a href={item.href}>
                                <img alt='' src={item.url} className="w-26 h-26 rounded-md border border-[#2789CA] p-1 mr-2 hover:-translate-y-px" />
                            </a>
                        </div>
                    ))}
                </div>
                <hr className="border-[#2789CA] border mx-[1.5rem] " />
                <div className="text-sm font-['Objektiv_Mk1_Trial'] p-[1.5rem] lg:pr-[3rem]">
                    &copy; 2023 Aqualina Beach House. All rights reserved
                </div>
            </div>
        </div>
    );
}


