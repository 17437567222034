import React, { useEffect, useState } from "react";
import AOS from 'aos';
import 'aos/dist/aos.css';
import "./welcome-to-aqualina.css"
import { WelcomeProp } from "../../Dtos/types/welcome-to-aqualina";
import { Link } from "react-router-dom";


export default function WelcomeToAqualina(props:WelcomeProp) {
    
    useEffect(() => {
        AOS.init();
      }, [])
    
    return (
        <>
        <div className="pt-14 pb-40 welcome-to-aqua">
            <div className="">
                <p className="welcome-title text-center">{props.title}</p>
            </div>
            <div className="grid grid-cols-2 gap-6 mt-20 welcome-web">
                <div className="pr-3">
                    <p data-aos="fade-right" className="welcome-caption mb-12">{props.caption}</p>
                    <img className="imageone" src={props.imageOne} />
                </div>
                <div className="pl-3 colun2">
                    <img className="imagetwo mb-10" src={props.imageTwo} />
                    <p data-aos="fade-left" className="welcome-text">{props.text}</p>
                    <Link to={props.link}>
                        <button type="button" className="rounded-full bg-white px-4 py-2.5 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 buttext welcome-but">{props.buttontext}</button>
                    </Link>
                </div>
            </div>

            {/* mobile view */}
            <div className="welcome-mobile mt-5 pr-5 pl-5">
                <p className="welcome-caption mb-10">{props.caption}</p>
                <img className="imageone img-welcome-mobile mb-5" src={props.mobileimageOne} />
                <img className="imagetwo img-welcome-mobile mb-5" src={props.mobileimageTwo} />
                <p className="welcome-text mb-5">{props.text}</p>
                <div className="mt-10 text-center">
                    <Link to={props.link}>
                        <button type="button" className="rounded-full bg-white px-4 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 buttext welcome-but">{props.buttontext}</button>
                    </Link>
                </div>
            </div>
            {/* mobile view */}
        </div>
        </>
    );
}