import React, { useEffect, useState } from "react";
import "./experience.css";
import AOS from 'aos';
import 'aos/dist/aos.css';
import { ExperienceProps } from "../../Dtos/types/experience-interface";


export default function Experience(props:ExperienceProps) {

    const [fullWidth, setFullWidth]=useState(props.fullWidth);
    
    useEffect(() => {
        AOS.init();
      }, []);

    const [fullImage, setFullImage] = useState(props.fullImage)
    
    
    return (
        <>
        {fullWidth? 
        <div className="experience">
            <div className="experience-web">
                <div className={fullImage?"mb-14":"pl-20 pr-20 mb-14"}>
                    <img className={fullImage? "experience-full-image":"experience-notfull-image"} data-aos="fade-up" src={props.imageOne} alt="" />
                </div>
                <div className="grid grid-cols-2 gap-20">
                    <div data-aos="fade-up" className="pl-20 mb-20">
                        <p className="experience-caption mb-10">{props.captionOne}</p>
                        <p className="experience-text">{props.textOne}</p>
                    </div>
                    <div></div>
                </div>
            </div>


            {/* mobile view */}
            <div className="experience-mobile mb-20">
                <img data-aos="fade-up" src={props.imageOne} alt="" className="experience-full-image"/>
                <div data-aos="fade-up" className="mb-20 mt-10 pr-5 pl-5">
                    <p className="experience-caption mb-3">{props.captionOne}</p>
                    <p className="experience-text">{props.textOne}</p>
                </div>
            </div>
            {/* mobile view */}
        </div>
        :
        <div className="experience">
            <div className="experience-web">
                <div className="grid grid-cols-2 gap-20 mb-20">
                    <div >
                        <img data-aos="fade-right" className="experience-image-one mb-14" src={props.imageOne} alt="" />
                        <div data-aos="fade-right" className="pl-20">
                            <p className="experience-caption mb-10">{props.captionOne}</p>
                            <p className="experience-text">{props.textOne}</p>
                        </div>
                    </div>
                    <div className="">
                        <img data-aos="fade-left" className="experience-image-two mb-14" src={props.imageTwo} alt="" />
                        <div data-aos="fade-left" className="pr-20">
                            <p className="experience-caption mb-10">{props.captionTwo}</p>
                            <p className="experience-text">{props.textTwo}</p>
                        </div>
                    </div>
                </div>
            </div>


            {/* mobile view */}
            <div className="experience-mobile pr-5 pl-5 mb-20">
                <img data-aos="fade-up" className="experience-image-one mb-10" src={props.imageOne} alt="" />
                <div data-aos="fade-up" className="mb-20">
                    <p className="experience-caption mb-3">{props.captionOne}</p>
                    <p className="experience-text">{props.textOne}</p>
                </div>
                <img data-aos="fade-up" className="experience-image-two mb-10" src={props.imageTwo} alt="" />
                <div data-aos="fade-up" className="">
                    <p className="experience-caption mb-3">{props.captionTwo}</p>
                    <p className="experience-text">{props.textTwo}</p>
                 </div>
            </div>
            {/* mobile view */}
        </div>}
        </>
    );
}