import { LocationProps } from "../Dtos/types/location";
export const LocationAndMapComponent = (props: LocationProps) => {
    const formattedText = props.text
    .split(/\r\n/g)
    .map((line, index) => <li key={index} className="md:pl-[20px]">{line}</li>);
    return (
        <div className="pt-[40px] md:pl-[50px] pl-[18px] w-full">
            <div className="md:flex md:justify-between items-center justify-center flex-row">
                <div className="md:w-1/2 w-full">
                    <h1 className="text-[#2789CA] md:text-[38px] text-[32px] font-[400] md:leading-[40.28px] leading-[33.92px] font-['Amalfi_Coast'] ">
                        {props.title}
                    </h1>
                    <div className="text-[#505457] text-[15px] leading-[30px] font-[700] font-['Objektiv_Mk1_Trial'] md:py-[50px] py-[20px]">
                        <span className="text-[#2789CA] text-[20px] leading-[40px] md:block hidden">YOU CAN FIND</span>
                        {formattedText}
                        {/* <li className="md:pl-[20px]">{props.text}</li> */}
                        {/* <li className="md:pl-[20px]">Mansa Beach Club</li> */}

                        <span className="text-[#2789CA] text-[20px] leading-[40px] md:block hidden">BOAT COORDINATES</span>

                        <li className="md:pl-[20px]">{props.coordinate}</li>

                        <span className="text-[#2789CA] text-[20px] leading-[40px] md:block hidden">TIME ZONE</span>

                        <li className="md:pl-[20px]">{props.timeZone}</li>

                    </div>
                </div>
                <div className="md:w-1/2">
                    <img className="md:max-w-full w-4/5 md:w-full h-full" src={props.logo} alt="aqua-mansa-logo" />
                </div>
            </div>
            <div className="flex justify-center items-center">
                <img src={props.map} alt="map-desktop" className="max-w-full w-[90rem] my-[10px] sm:block hidden" data-aos="zoom-in" data-aos-duration="2000" />
                <img src={props.map} alt="map-mobile" className="w-full block sm:hidden object-cover h-[40rem]" data-aos="zoom-in" data-aos-duration="2000" />
            </div>
        </div>
    )
}