import React from 'react';
import { HollowDotsSpinner } from "react-epic-spinners";
import { ToastContainer } from 'react-toastify';

export function Loader(props: { size?: any; color?: any; className?:string}) {
    const {size, color, className} = props; 
    return (
        <>
        <HollowDotsSpinner className={className? className: ""} size={!size ? 20 : size} color={!color ? "#000" : color == '#4D627E' ? "#4D627E" : color} />
        </>
        
    );
}