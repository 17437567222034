import React, { useEffect, useState } from "react";
import "./join-africa.css"
import { JoinAfricaProp } from "../../Dtos/types/join-africa-interface";
import AOS from 'aos';
import 'aos/dist/aos.css';
import { Link } from "react-router-dom";


export default function JoinAfrica(props:JoinAfricaProp) {

    useEffect(() => {
        AOS.init();
      }, [])
    
    
    
    return (
        <>
        <div style={{background: `url(${props.backgroundimage})`}} className="pb-20 join-africa">
            <div className="mb-5">
                <p className="join-africa-title text-center">{props.title}</p>
            </div>
            <div className="join-africa-caption-div mt-10">
                <p data-aos="fade-up" className="join-africa-caption text-center">{props.bodytext}</p>
                <p data-aos="fade-up" className="join-africa-caption text-center">{props.altBodyText}</p>
            </div>
            <div className="text-center mt-10">
                <Link to={props.link}>
                    <button data-aos="fade-up" type="button" className="rounded-full bg-white px-10 py-4 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 join-africa-buttext">{props.buttontext}</button>
                </Link>
            </div>
            
        </div>
        </>
    );
}