import { useState } from "react";
import { XMarkIcon } from '@heroicons/react/24/outline'
import { ImageVideoProps } from "../Dtos/types/image-video";

export const ImageAndTextFullWidthComponent = (props: ImageVideoProps) => {
    const [isPlaying, setIsPlaying] = useState(false)
    const handlePlay = () => {
        setIsPlaying(true);
    };

    const handleClose = () => {
        setIsPlaying(false);
    };

    return (
        <div>
             <div>
                {isPlaying && props.playButton !== undefined ? (
                    <>
                        <div>
                            <XMarkIcon onClick={handleClose} className='text-gray-400 hover:text-gray-500 pl-4 pt-4 h-[50px] w-[50px] aria-hidden="true" z-[1] absolute cursor-pointer' />
                            <video controls autoPlay className="w-full h-auto">
                                <source src={props.video} />
                            </video>
                        </div>
                    </>
                ) : (
                    <div className="w-full">
                        <div className='relative w-full'>
                            <div className="absolute inset-0 flex items-center justify-center" onClick={handlePlay}>
                                <img src={props.playButton} className='hover:-translate-y-6 transition delay-75 duration-300 ease-in-out cursor-pointer'/>
                            </div>
                            <div className="w-full md:h-auto ">
                                <img src={props.image} className="w-full h-auto md:block hidden" alt="video_img" />
                                {props.playButton === undefined ? 
                                <img src={props.image} className="w-[full] h-[35rem] object-cover block md:hidden" alt="video_img" />
                                :<img src={props.image} className="w-[full] object-cover h-[23.125rem] block md:hidden" alt="video_img" />
                                 }
                            </div>
                        </div>
                    </div>
                )}
            </div> 
        </div>
    )
}



