import { MissionProps } from "../Dtos/types/mission";

export const StylizedTextComponent = (props: MissionProps) => {
    const formattedText = props.text.replace(/\r\n/g, '<br>');
    const textArray = formattedText.split(props.focusText);


    return (
        <div className="bg-black/[.06] w-full h-[auto] md:py-[7rem] py-[3rem] text-left font-[400] flex px-[4vw] md:pl-[50px] md:pr-[20vw] items-center">
            <p data-aos="fade-up" data-aos-duration="2000" data-aos-easing="ease-in-out-cubic" className="text-[#505457] text-[36px] md:text-[64px] md:leading-[65.92px] leading-[43.2px] font-['Dante_MT_Std']">
                {textArray.map((formattedText, index) => (
                    <>
                        <span dangerouslySetInnerHTML={{ __html: formattedText }} /> 
                        {index < textArray.length - 1 && (
                            <span className="text-[#2789CA] text-[36px] leading-[43.2px] md:text-[54px] md:leading-[55.62px] font-['Amalfi_Coast']">
                                {props.focusText}
                            </span>
                        )}
                        
                    </>
                ))}
            </p>
        </div>
    );
}



