import { useState } from 'react'
import { Dialog } from '@headlessui/react'
import { Bars3Icon, XMarkIcon } from '@heroicons/react/24/outline'
import { FullHorizontalCarouselComponent } from '../../Components/full-horizontal-carousel-component/full-horizontal-carousel-component'
import { FullHorizontalCarouselComponentProp } from '../../Dtos/full-horizontal-carousel-component-prop'
import { FullWidthGalleryCarouselComponentProp } from '../../Dtos/full-width-gallery-carousel-prop'
import { FullWidthGalleryCarouselComponent } from '../../Components/full-width-gallery-carousel/full-width-gallery-carousel'

const navigation = [
  { name: 'Product', href: '#' },
  { name: 'Features', href: '#' },
  { name: 'Marketplace', href: '#' },
  { name: 'Company', href: '#' },
]

export default function HomeIndex() {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false)
  const data : FullHorizontalCarouselComponentProp = { 
    carouselContent: [
      {
        image: "https://i.ibb.co/6n20HNB/Rectangle-3.png",
        route: "",
        title: "Master Suites"
      },
      {
        image: "https://i.ibb.co/6n20HNB/Rectangle-3.png",
        route: "",
        title: "Aqua Rooms"
      },
      {
        image: "https://i.ibb.co/6n20HNB/Rectangle-3.png",
        route: "",
        title: "Lina Rooms"
      },
    ],
    title: "Rooms & Suite"
  }
  const imageData : FullWidthGalleryCarouselComponentProp = {
    images : [
      "https://swiperjs.com/demos/images/nature-1.jpg", 
    "https://swiperjs.com/demos/images/nature-2.jpg",
    "https://swiperjs.com/demos/images/nature-3.jpg",
    "https://swiperjs.com/demos/images/nature-4.jpg",
    "https://swiperjs.com/demos/images/nature-5.jpg"
  ],
    route: "",
    title: "Relive those WONDERFUL AQUALINA Moments in our Gallery",
    routeLabel: "Explore Gallery"
  }
  return (
    <div className="bg-white">
     <FullHorizontalCarouselComponent carouselContent={data.carouselContent}  title={data.title} />
     <FullWidthGalleryCarouselComponent images={imageData.images} title={imageData.title} route={imageData.route} routeLabel={imageData.routeLabel} />
    </div>
    
  )
}
