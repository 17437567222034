import React from 'react';
import { ToastContainer } from 'react-toastify';
import { SubscriberComponent } from '../../Components/SuscriberComponent';
import { FooterComponent } from '../../Components/FooterComponent';
import { LayoutProps } from '../../Dtos/types/layout-props';
import { HeroComponent } from '../../Components/HeroComponent';
import { SlideComponentItem } from '../../Dtos/types/hero';

const Layout: React.FC<LayoutProps> = ({ children, heroProp }) => {
    return (
      <>
        <main>
          {
            Array.isArray(heroProp.slideContent)? 
            <HeroComponent slideContent={heroProp.slideContent as SlideComponentItem[]} showSlide = {heroProp.showSlide} />
            :
          <HeroComponent slideContent={heroProp.slideContent as SlideComponentItem} showSlide = {heroProp.showSlide} />

          }
          {children}
          </main>
        <SubscriberComponent/>
        <FooterComponent/>
      </>
    );
  };
  export default Layout;