import { ToastOptions, TypeOptions, toast } from "react-toastify";
import { MessageType } from "../Enums/message-type";


const alertOptions: ToastOptions = {
    position: "top-right",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "colored",
};

export default function fire(_messageType: MessageType, _message: string) {
    switch (_messageType) {
        case MessageType.info:
            toast.info(_message, alertOptions);
            break;
        case MessageType.warning:
            toast.warn(_message, alertOptions);
            break;
        case MessageType.error:
            toast.error(_message, alertOptions);
            break;
        case MessageType.success:
            toast.success(_message, alertOptions);
            break;
        default:
            toast(_message, alertOptions);
            break;
    }
}

