import React, { useEffect, useState } from "react";
import "./private-events.css"
import { PrivateEventProp } from "../../Dtos/types/private-events-interface";
import AOS from 'aos';
import 'aos/dist/aos.css';
import { Link } from "react-router-dom";


export default function PrivateEvents(props:PrivateEventProp) {

    useEffect(() => {
        AOS.init();
      }, [])
    
    
    return (
        <>
        <div style={{background: `url(${props.backgroundimage})`}} className="pb-20 private-events private-events-web">
            <div className="mb-20">
                <p className="private-events-title text-center">{props.title}</p>
            </div>
            <div className="private-events-caption-div mt-20">
                <p data-aos="fade-up" className="private-events-caption text-center">{props.bodytext}</p>
                <p data-aos="fade-up" className="private-events-caption text-center">{props.altBodyText}</p>
            </div>
            <div className="text-center mt-20">
                <Link to={props.link}>
                    <button data-aos="fade-up" type="button" className="rounded-full bg-white px-10 py-4 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 private-events-buttext">{props.buttontext}</button>
                </Link>
            </div>
            
        </div>
        
        {/* mobile view */}
        <div style={{background: `url(${props.mobilebackgroundimage})`}} className="pt-20 pb-20 private-events private-events-mobile">
            <div className="mb-20">
                <p className="private-events-title text-center">{props.title}</p>
            </div>
            <div className="private-events-caption-div mt-60">
                <p className="private-events-caption text-center">{props.bodytext}</p>
                <p className="private-events-caption text-center">{props.altBodyText}</p>
            </div>
            <div className="text-center mt-5 mb-40">
                <Link to={props.link}>
                    <button type="button" className="rounded-full bg-white px-10 py-4 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 private-events-buttext">{props.buttontext}</button>
                </Link>
            </div>
            
        </div>
        {/* mobile view */}
        </>
    );
}