import React, { useEffect, useState } from "react";
import { AboutUsProps } from "../../Dtos/types/about-us";
import "./about-us.css";
import AOS from 'aos';
import 'aos/dist/aos.css';


export default function AboutUs(props:AboutUsProps) {
    
    useEffect(() => {
        AOS.init();
      }, [])
    
    return (
        <>
        <div className="">
            <div className="about-one-web">
                <div className="grid grid-cols-2">
                    <div className="">
                        <img className="about-us-image" src={props.imageOne} />
                    </div>
                    <div className="about-us-text-div">
                        <p data-aos="fade-left" className="about-us-text">{props.text}</p>
                    </div>
                </div>
                <div className="grid grid-cols-2">
                    <div className="caption-section">
                        <p data-aos="fade-right" className="joie-caption">{props.caption}</p>
                        <p data-aos="fade-right" className="joie-captext">{props.captext}</p>
                    </div>
                    <div className="">
                        <img className="about-us-image" src={props.imageTwo} />
                    </div>
                </div>
            </div>

            {/* mobile view */}
            <div className="about-one-mobile px-5 mb-10">
                <img className="about-us-image pt-5" src={props.imageOneMobile} />
                <p className="about-us-text pt-5">{props.text}</p>
                <img className="about-us-image pt-5" src={props.imageTwoMobile} />
                <div className="caption-section pt-5">
                    <p className="joie-caption ">{props.caption}</p>
                    <p className="joie-captext">{props.captext}</p>
                </div>
            </div>
            {/* mobile view */}

        </div>
        </>
    );
}