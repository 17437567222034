import React, { useEffect, useState } from "react";
import "./aqualina-exudes.css";
import AOS from 'aos';
import 'aos/dist/aos.css';
import { AqualinaExudesProps } from "../../Dtos/types/aqualina-exudes-interface";


export default function AqualinaExudes(props:AqualinaExudesProps) {
    
    useEffect(() => {
        AOS.init();
      }, [])
    
    return (
        <>
        <div className="aqualina-exudes">
            <div  className="aqualina-exudes-web">
                <div className="grid grid-cols-2 gap-20">
                    <div className="aqualina-exudes-column1">
                        <p data-aos="fade-right" className="aqualina-exudes-caption">{props.caption}</p>
                        <div className="aqualina-exudes-img-one ae-fix-width">
                            <img data-aos="fade-up-right" className="aqualina-exudes-images ae-fix-width" src={props.imageOne} alt="" />
                        </div>
                    </div>
                    <div className="">
                        <img data-aos="fade-down-left" className="aqualina-exudes-images mb-10 ae-fix-width" src={props.imageTwo} alt="" />
                        <img data-aos="fade-up-left" className="aqualina-exudes-images ae-fix-width" src={props.imageThree} alt="" />
                    </div>
                </div>
            </div>


            {/* medium view */}
            <div className="aqualina-exudes-medium">
                <div className="grid grid-cols-2 gap-10">
                    <div className="aqualina-exudes-column1">
                        <p data-aos="fade-right" className="aqualina-exudes-caption">{props.caption}</p>
                        <div className="aqualina-exudes-img-one ae-fix-width">
                            <img data-aos="fade-up-right" className="aqualina-exudes-images ae-fix-width" src={props.imageOne} alt="" />
                        </div>
                    </div>
                    <div className="">
                        <img data-aos="fade-down-left" className="aqualina-exudes-images mb-7 ae-fix-width" src={props.imageTwo} alt="" />
                        <img data-aos="fade-up-left" className="aqualina-exudes-images ae-fix-width" src={props.imageThree} alt="" />
                    </div>
                </div>
            </div>
            {/* medium view */}


            {/* mobile view */}
            <div className="aqualina-exudes-mobile pr-10 pl-10 mt-20">
                <img data-aos="fade-up" className="aqualina-exudes-images mb-10 ae-fix-width" src={props.imageOneMobile} alt="" />
                <p data-aos="fade-up" className="aqualina-exudes-caption mb-10">{props.caption}</p>
                <img data-aos="fade-up" className="aqualina-exudes-images mb-10 ae-fix-width" src={props.imageTwoMobile} alt="" />
                <img data-aos="fade-up" className="aqualina-exudes-images mb-12 ae-fix-width" src={props.imageThreeMobile} alt="" />
            </div>
            {/* mobile view */}

        </div>
        </>
    );
}