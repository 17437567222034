import React, { useState } from 'react';
import { MenuSectionProps, menuHighlightItem } from "../Dtos/types/menu-section";

export const MenuSectionComponent: React.FC<MenuSectionProps> = ({ menuHighlights, menuSummary }) => {
  const [selectedCategory, setSelectedCategory] = useState<string>(menuHighlights[0].menuTitle);
  const menuCategories: string[] = menuHighlights.map((highlight: menuHighlightItem) => highlight.menuTitle);
  const mobileMenuCategories: string[] = menuHighlights.slice(0, 3).map((highlight: menuHighlightItem) => highlight.menuTitle);
  const menuItems: Record<string, { title: string, details: string[] }[]> = {};

  if (menuHighlights) {
    menuHighlights.forEach((highlight: menuHighlightItem) => {
      const category = highlight.menuTitle;

      if (!menuItems[category]) {
        menuItems[category] = [];
      }

      if (highlight.menuItems) {
        highlight.menuItems.forEach((menuItem) => {
          menuItems[category].push({
            title: menuItem.title,
            details: [menuItem.details],
          });
        });
      }
    });
  }

  const changeCategory = (category: string) => {
    setSelectedCategory(category);
  };

  console.log('selectedCategory:', selectedCategory);
  console.log('menuCategories:', menuCategories);
  console.log('menuItems:', menuItems);

  return (
    <div className="w-full justify-between md:p-12 p-[0.5rem] py-[2rem] bg-[#E5EDF2] lg:h-[51.875rem]">
      <div className="lg:flex lg:justify-between justify-center items-center font-['Objektiv_Mk1_Trial']">
        <div className="max-[1024px]:mx-auto text-center border-none md:w-[25.625rem] w-[17.4375rem] h-[17.4375rem] md:h-[25.625rem] bg-[#FFFFFF] border-[#FFFFFF] lg:mx-[6%] shadow-md p-[1.0rem] md:p-[1.4rem] rounded-[100%]">
          <div className="border bg-[#FFFFFF] border-black rounded-[100%] md:w-[22.855rem] md:h-[22.855rem] w-[15.5525rem] h-[15.5525rem]">
            <h1 className='font-[400] md:text-[1.5rem] text-[1.020625rem] leading-[1.1225rem] md:leading-[1.65rem]'>MENU</h1>
            <div>
              <ul className='grid grid-rows-4 grid-flow-col gap-1 font-[500] md:text-[1.125rem] text-[0.765625rem] leading-[0.841875rem] md:leading-[1.2375rem] md:px-[1.0rem] md:pt-[1rem]'>
                {menuSummary.map((item, index) => (
                  <li key={index} className='md:py-[0.2rem] py-0'>
                    {item.split(' ').map((word, wordIndex) => (
                      <span key={wordIndex} style={{ opacity: word.includes('(') && word.includes(')') ? '0.5' : '1' }}>
                        {word.replace('(', ' ' + '(')}
                      </span>
                    ))}
                    <span className='text-[#505457]/[.5]'> /</span>
                  </li>
                ))}
              </ul>
            </div>
            <div>
              <button className="border border-[#3D3D3D] rounded-full cursor-pointer md:my-[1rem] my-[0.5rem] md:py-[0.625rem] md:px-[1.5rem] px-[1.020625rem] py-[0.425rem] font-[500] md:text-[0.875rem] md:leading-[1.3125rem] text-[0.595625rem] leading-[0.893125rem">View Food Menu</button> <br />
              <button className="border border-[#3D3D3D] rounded-full cursor-pointer md:py-[0.625rem] md:px-[1.5rem] px-[1.020625rem] py-[0.425rem] font-[500] text-[0.595625rem] leading-[0.893125rem] md:text-[0.875rem] md:leading-[1.3125rem]">View Drink Menu</button>
            </div>
          </div>
        </div>
        <div className='md:px-[2rem] w-full p-[0.6rem]'>
          <h1 className="font-['Dante_MT_Std'] font-[400] md:text-[3.75rem] md:leading-[4.125rem] text-[2.5rem] leading-[2.75rem] text-[#505457] pb-[2rem]">Menu Highlights</h1>
          <div className='border border-[#3D3D3D]/[.7] w-full' />
          <ul className="lg:flex wrap cursor-pointer justify-between hidden w-full">
            {menuCategories.map((category, index) => (
              <li
                key={index}
                className={`${category === selectedCategory ? 'text-[#505457]' : 'text-[#505457]/[.5]'}`}
                onClick={() => changeCategory(category)}
                style={{ paddingTop: '1rem', fontFamily: '500', fontSize: '0.875rem', lineHeight: '0.9625' }}
              >
                {category}
              </li>
            ))}
          </ul>
          <ul className='lg:hidden flex wrap justify-between cursor-pointer'>
            {mobileMenuCategories.map((category, index) => (
              <li
                key={index}
                className={`${category === selectedCategory ? 'text-[#505457]' : 'text-[#505457]/[.5]'}`}
                onClick={() => changeCategory(category)}
                style={{ paddingTop: '1rem', fontFamily: '500', fontSize: '0.875rem', lineHeight: '0.9625' }}
              >
                {category}
              </li>
            ))}
          </ul>
          {menuItems[selectedCategory] && (
            <ul className='py-[2rem]'>
              {menuItems[selectedCategory].map((section, sectionIndex) => (
                <div key={sectionIndex}>
                  <h2 className='font-[500] text-[#3D3D3D] text-[1.8125rem] leading-[1.99375rem] py-[2rem]'>{section.title}</h2>
                  {section.details.map((item, itemIndex) => (
                    <li key={itemIndex} className='font-[400] text-[#505457] text-[1rem] leading-[1.85rem]'>{item}</li>
                  ))}
                </div>
              ))}
            </ul>
          )}
        </div>
      </div>
    </div>
  );
};






