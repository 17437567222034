import { useState } from 'react';
import { XMarkIcon } from '@heroicons/react/24/outline'
import { TextContComponent } from './TextContComponent';
import { VideoBoxProps } from "../Dtos/types/video-box";


export const VideoBoxComponent = (props: VideoBoxProps) => {
  const [isPlaying, setIsPlaying] = useState(false);

  const handlePlay = () => {
    setIsPlaying(true);
  };

  const handleClose = () => {
    setIsPlaying(false);
  };

  return (
    <div className="md:h-full bg-[#F3F3F3] w-full max-w-full">
      {isPlaying ? (
        <>
          <div className='h-full'>
            <div className=''>
              <XMarkIcon onClick={handleClose} className='text-gray-400 hover:text-gray-500 pl-4 pt-4 h-[50px] w-[50px] aria-hidden="true" z-[1] absolute' />
            </div>
            <video controls autoPlay className="w-full h-auto">
              <source src={props.video} />
            </video>
          </div>
        </>
      ) : (
        <div className='bg-[#F3F3F3] relative'>
          <div className="w-full pb-[80px] h-auto bg-[#000000] ">
            <p className="text-[#FFFFFF] text-center text-[16px] font-[700] bg-[#000000] leading-[25.5px] py-[2.5rem] font-['Objektiv_Mk1_Trial']">
              MANSA BEACH CLUB
            </p>
            <div className="md:flex md:relative w-full">
              <div className='md:flex md:justify-center md:items-center md:w-1/4 hidden block'><TextContComponent
            text={props.text}
            title={props.title}
            darkMode={props.darkMode}
            cardPosition={props.cardPosition}
            button={props.button}
            link={props.link}
              /></div>
              <div className='relative'>
                <div className="absolute inset-0 flex items-center justify-center" onClick={handlePlay}>
                  <img src={props.playButton} className='hover:-translate-y-6 cursor-pointer transition delay-75 duration-300 ease-in-out' alt="playbutton" />
                </div>
                <div className="md:right-0 flex-end z-[1] w-full sm:block hidden">
                  <img src={props.image} alt="bg-img" />
                </div>
                <div className="sm:hidden block">
                  <img src={props.image} className='h-[25rem] w-full object-cover' alt="bg-img" />
                </div>
              </div>
            </div>
            <div>
            </div>
          </div>
          <div className='mx-[1.5rem] -mt-[5rem] md:hidden md:block flex justify items-center'><TextContComponent
            text={props.text}
            title={props.title}
            darkMode={props.darkMode}
            cardPosition={props.cardPosition}
            button={props.button}
            link={props.link}
          /> </div>
        </div>
      )}
    </div>
  );
};




