import React, { useState } from "react";
import { FullWidthGalleryCarouselComponentProp } from "../../Dtos/full-width-gallery-carousel-prop";
import './full-width-gallery-carousel.css';
import { Link } from "react-router-dom";
import prevNav from '../../Assets/img/prev.png';
import nextNav from '../../Assets/img/next.png';
export const FullWidthGalleryCarouselComponent: React.FC<FullWidthGalleryCarouselComponentProp> = ({ title, images, route, routeLabel }) => {
    const [firstIndex, setfirstIndex] = useState(1);

    function MoveToSlide(index: number) {
        console.log({ index: index })
        //    let allElement = document.getElementsByClassName("slide-radio");
        //    for (let elIndex = 0; elIndex < allElement.length; elIndex++) {
        //     const element = allElement[elIndex];
        //     element.removeAttribute("checked");
        //    }
        let allDotElement = document.getElementsByClassName("slide-dots");
        for (let dotIndex = 0; dotIndex < allDotElement.length; dotIndex++) {
            const dotElement = allDotElement[dotIndex];
            dotElement.className = "slide-dots";
        }
        let nextElement = document.getElementById(`t-${index}`);
        nextElement?.click();
        let activeDotElement = document.getElementById(`l-${index}`) as HTMLElement;
        activeDotElement.className = "slide-dots active";
        setfirstIndex(index);
    }
    function NextSlide() {
        let nextIndex = firstIndex + 1;
        if (nextIndex > 5) nextIndex = 1;
        MoveToSlide(nextIndex);
    }
    function PreviousSlide() {
        let previousIndex = firstIndex - 1;
        if (previousIndex < 1) previousIndex = 5;
        MoveToSlide(previousIndex);
    }
    return (
        <section className="image-carousel bg-white relative">
            <div className="container mx-auto px-12 py-14 max-sm:px-2">
                <div className="image-carousel-title mb-10 max-w-[30%] m-auto max-sm:max-w-[100%]">
                    <h4 className="uppercase font-Objektiv font-bold text-center text-[#2789CA] text-base leading-6 max-sm:leading-4 max-sm:text-xs">{title}</h4>
                </div>
                <div className="slider">
                    {
                        images.map((img, index) => (
                            index === 0 ?
                                <input type="radio" name="testimonial" className="slide-radio" id={`t-${index + 1}`} defaultChecked={true} />
                                :
                                <input type="radio" name="testimonial" className="slide-radio" id={`t-${index + 1}`} />
                        ))
                    }

                    <div className="testimonials">
                        {
                            images.map((img, index) => (
                                <label className="item" htmlFor={`t-${index + 1}`}>
                                    <img src={img} alt="" />
                                </label>
                            ))
                        }
                    </div>
                    <br />

                </div>
                <div className="max-sm:hidden slider-navigation absolute top-0 w-[82.5%]">
                    <div onClick={() => PreviousSlide()} className="slider-prev absolute w-[54px] h-[113px] top-[241px] left-[23px] cursor-pointer">
                        <img src={prevNav} alt="" />
                    </div>
                    <div onClick={() => NextSlide()} className="slider-next absolute w-[54px] h-[113px] top-[241px] right-[35px] cursor-pointer">
                        <img src={nextNav} alt="" />
                    </div>
                </div>
                <div className="hidden -mt-12 max-sm:flex dots relative z-10">
                    {
                        images.map((img, index) => (
                            index === 0 ?
                                <label htmlFor={`t-${index + 1}`} id={`l-${index + 1}`} className="active slide-dots" onClick={() => MoveToSlide(index + 1)} />
                                :
                                <label htmlFor={`t-${index + 1}`} id={`l-${index + 1}`} className="slide-dots" onClick={() => MoveToSlide(index + 1)} />

                        ))
                    }
                </div>
                <div className="image-carousel-action text-center my-10">
                    <Link className="rounded-full bg-white border-2 border-[#2789CA] px-3.5 py-2 text-sm font-semibold text-[#2789CA] shadow-sm hover:bg-white focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-[#2789CA]" to={route}>{routeLabel}</Link>
                </div>
            </div>
        </section>

    )
}