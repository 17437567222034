import { useState, useEffect } from 'react'
import { SpotlightComponent } from "../../Components/SpotlightComponent";
import { ImageAndTextFullWidthComponent } from "../../Components/ImageAndTextFullWidthComponent";
import { LocationAndMapComponent } from "../../Components/LocationAndMapComponent";
import { StylizedTextComponent } from "../../Components/StylizedTextComponent";
import AboutUs from "../../Components/about-us/about-us";
import joievivreimageonemobile from "../../images/aboutonemobile1.png";
import joievivreimagetwomobile from "../../images/aboutonemobile2.png";
import PrivateEvents from "../../Components/private-events/private-events";
import privateEventsImageMobile from "../../images/privatemobile.png"
import { ApiManager } from '../../Utilities/api-manager';
import AboutLocation from '../../Components/AboutLocation';
import Layout from '../Common/page-layout';
import { Loader } from '../../Utilities/loader';


export function AboutPage() {
  const [responseData, setResponseData] = useState<any | null>(null);

  useEffect(() => {
    const fetchData = async () => {
      const response = await ApiManager('/page/about-us', 'GET');
      setResponseData(response?.data);
      console.log(response?.data);
    };

    fetchData();
  }, []);

  return (
    <>
      {
        responseData ?
          <Layout heroProp={{ showSlide: false, slideContent: responseData.HeroSection }}>
            <StylizedTextComponent text={responseData.DreamScapeSection.content} focusText={responseData.DreamScapeSection.contentCursive} />
            <AboutUs
              imageOne={responseData.OurStorySection.firstCard.imageUrl}
              imageTwo={responseData.OurStorySection.secondCard.imageUrl}
              imageOneMobile={joievivreimageonemobile}
              imageTwoMobile={joievivreimagetwomobile}
              caption={responseData.OurStorySection.secondCard.title}
              captext={responseData.OurStorySection.secondCard.content}
              text={responseData.OurStorySection.firstCard.content}
            />
            <ImageAndTextFullWidthComponent
              video={undefined}
              image={responseData.BrandSection.imageUrl}
              playButton={undefined}
            />
            <StylizedTextComponent text={responseData.BrandSection.content} focusText='' />
            <SpotlightComponent
              image={responseData.FounderSection.founderCard.imageUrl}
              text={responseData.FounderSection.founderCard.content}
              title={responseData.FounderSection.founderCard.title}
              designation={responseData.FounderSection.founderDesignation}
              name={responseData.FounderSection.founderName}
            />
            <ImageAndTextFullWidthComponent
              video={responseData.VideoSection.videoUrl}
              image='./images/video-img-section/about.png'//TODO:  add overlay image
              playButton="/images/icons/playbutton.png"
            />
            <StylizedTextComponent text={responseData.VideoSection.videoDescription} focusText='' />
            <PrivateEvents
              backgroundimage={responseData.PrivateEventSection.imageUrl}
              mobilebackgroundimage={privateEventsImageMobile}
              title={responseData.PrivateEventSection.title}
              buttontext="View Events Calendar"
              bodytext={responseData.PrivateEventSection.title}
              altBodyText=""
              link={responseData.PrivateEventSection.link}
            />
            <AboutLocation
              image={responseData.OurLocationSection.locationCard.imageUrl}
              text={responseData.OurLocationSection.locationCard.content}
              caption='ABOUT OUR LOCATION'
            />

            <LocationAndMapComponent
              title={responseData.OurLocationSection.locationTitle}
              map={responseData.OurLocationSection.locationMapUrl}
              text={responseData.OurLocationSection.locationFeatures}
              timeZone={responseData.OurLocationSection.locationTimeZone}
              coordinate={responseData.OurLocationSection.locationCoordinates}
              logo={responseData.OurLocationSection.locationImageUrl}
            />
          </Layout> : <Loader />
      }


    </>

  )
}