import React, { useEffect, useState } from "react";
import "./founder-card-left.css";
import AOS from 'aos';
import 'aos/dist/aos.css';
import { FounderCardLeftProps } from "../../Dtos/types/founder-card-left";
import { Link } from "react-router-dom";


export default function FounderCardLeft(props:FounderCardLeftProps) {
    
    useEffect(() => {
        AOS.init();
      }, [])
    
    return (
        <>
        <div style={{marginBottom:props.bottommargin}} className="founder-card-one">
            <div  className="founder-card-one-web">
                <div className="grid grid-cols-2">
                    <div className="founder-card-one-leftgrid">
                        <img className="founder-card-one-image" style={{width:props.width}} src={props.image} alt="" />
                    </div>
                    <div className="founder-card-one-words founder-card-one-rightgrid">
                        <div >
                            <div>
                                <p data-aos="fade-left" className="founder-card-one-name">{props.name}</p>
                                <p data-aos="fade-left" className="founder-card-one-title">{props.title}</p>
                                <p data-aos="fade-left" className="founder-card-one-company">{props.company}</p>
                            </div>
                            <div> 
                                <p data-aos="fade-left" className="founder-card-one-text">{props.text}</p>
                            </div>
                        </div>
                        <div hidden={props.hidebutton} className="">
                            <Link to={props.link}>
                                <button data-aos="fade-left" className="rounded-full bg-white px-4 py-2.5 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 founder-card-one-button">{props.buttontext}</button>
                            </Link>
                        </div>
                    </div>
                </div>
            </div>

            {/* mobile view */}
            <div className="founder-card-one-mobile pl-5 pr-5">
                <img className="fco-mobile-img" style={{width:"100%"}} src={props.imageMobile} alt="" />
                <p className="founder-card-one-name mt-3">{props.name}</p>
                <p className="founder-card-one-title">{props.title}</p>
                <p className="founder-card-one-company">{props.company}</p>
                <p className="founder-card-one-text mt-5">{props.text}</p>
                <div hidden={props.hidebutton} className="mt-5 mb-10">
                    <Link to={props.link}>
                        <button className="rounded-full bg-white px-4 py-2.5 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 founder-card-one-button">{props.buttontext}</button>
                    </Link>
                </div>
            </div>
            {/* mobile view */}

        </div>
        </>
    );
}