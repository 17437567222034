import React, { useEffect, useState } from "react";
import "./room-detail.css";
import AOS from 'aos';
import 'aos/dist/aos.css';
import { RoomDetailProps } from "../../Dtos/types/room-detail-interface";
import { Link } from "react-router-dom";


export default function RoomDetail(props:RoomDetailProps) {
    
    useEffect(() => {
        AOS.init();
      }, [])
    
    return (
        <>
        <div className="room-detail">
            <div className="room-detail-web pb-20 pt-20">
                <div className="grid grid-cols-2 gap-20 room-detail-grid">
                    <div className="">
                        <img className="room-detail-image" src={props.roomImage} alt="" />
                    </div>
                    <div className="room-detail-column2">
                        <p data-aos="fade-left" className="room-detail-title">{props.title}</p>
                        <p data-aos="fade-left" className="room-detail-text">{props.text}</p>
                        <div data-aos="fade-left" className="room-detail-spec-group">
                            <p className="room-detail-spec">Number of Rooms:{props.number}</p>
                            <p className="room-detail-spec">Room Size:{props.size}</p>
                            <p className="room-detail-spec">Bathroom Size:{props.bath}</p>
                            <p className="room-detail-spec">Guests:{props.guests}</p>
                            <p className="room-detail-spec">Bedding:{props.bedding}</p>
                        </div>
                        <div>
                            <Link to={props.link}>
                                <button data-aos="fade-left" className="rounded-full bg-white px-4 py-2.5 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 room-detail-button">{props.buttonText}</button>
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
            

            {/* mobile view */}
            <div className="room-detail-mobile">
                <div data-aos="fade-up">
                    <img style={{width:"100%"}} src={props.roomImage} alt="" />
                </div>
                <p className="room-detail-title">{props.title}</p>
                <p className="room-detail-text">{props.text}</p>
                <div className="room-detail-spec-group">
                    <p className="room-detail-spec">{props.number}</p>
                    <p className="room-detail-spec">{props.size}</p>
                    <p className="room-detail-spec">{props.bath}</p>
                    <p className="room-detail-spec">{props.guests}</p>
                    <p className="room-detail-spec">{props.bedding}</p>
                </div>
                <div className="mb-5">
                    <Link to={props.link}>
                        <button className="rounded-full bg-white px-4 py-2.5 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 room-detail-button">{props.buttonText}</button>
                    </Link>
                </div>
            </div>
            {/* mobile view */}


        </div>
        </>
    );
}