import { RouteObject } from "./Dtos/route-object";
import DineAndDrinkIndex from "./Pages/DineAndDrink";
import { AqualinaFrontend } from "./Pages/Sample/AqualinaFrontend";
import { AboutPage } from "./Pages/About-us/aboutpage";
import TestPage from "./Pages/Sample/testpage";
import RoomsMain from "./Pages/Rooms/rooms-main";
import ExperienceIndex from "./Pages/Experience";
import OurTeamIndex from "./Pages/Our-teams";
import HomeIndex from "./Pages/Sample/new-sample";
import { HomePage } from "./Pages/Home/new-home";
import SingleRoom from "./Pages/Rooms/single-room";
import ShopPage from "./Pages/Shop";
import ContactUsPage from "./Pages/Contact-us";

export const AppRoute :  RouteObject[] = [
    {
        path: "/",
        element : <HomePage/>
    },
    {
        path: "/daniel",
        element : <TestPage />
    },
    {
        path: "/nifemi",
        element: <AqualinaFrontend/>
    },
    {
        path: "/ezekiel",
        element: <HomeIndex/>
    },
    {
        path: "/about-us",
        element: <AboutPage/>
    },
    {
        path: "/dine-and-drink",
        element: <DineAndDrinkIndex/>
    },
    
    {
        path: "/rooms",
        element: <RoomsMain />
    },
    {
        path: "/experience",
        element: <ExperienceIndex/>
    },
    {
        path: "/our-teams",
        element: <OurTeamIndex/>
    },
    {
        path: "/room-details",
        element: <SingleRoom/>
    },

    {
        path: "/shop",
        element: <ShopPage />
    },

    {
        path: "/contact-us",
        element: <ContactUsPage />
    },
    
]

