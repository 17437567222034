import React, { useState, useEffect } from "react";
import { MenuComponent } from "./MenuComponent";
import { HeroProps, SlideComponentItem } from "../Dtos/types/hero";
import AOS from 'aos';
import 'aos/dist/aos.css';

export const HeroComponent: React.FC<HeroProps> = ({ slideContent, showSlide }) => {
    useEffect(() => {
        AOS.init();
    }, []);

    const [isSignedIn, setIsSignedIn] = useState(false);
    const [menuOpen, setMenuOpen] = useState(false);
    const [currentImage, setCurrentImage] = useState(0);

    const handleSignin = () => {
        setIsSignedIn(true);
    }

    const handleOpenMenu = () => {
        setMenuOpen(true);
    };

    const handleCloseMenu = () => {
        setMenuOpen(false);
    };

    useEffect(() => {
        if (showSlide === true) {
            const interval = setInterval(() => {
                setCurrentImage((prevImage) =>
                    prevImage === (slideContent as SlideComponentItem[]).length - 1 ? 0 : prevImage + 1
                );
            }, 5000);

            return () => {
                clearInterval(interval);
            };
        }
    }, [showSlide, currentImage, slideContent]);
    const slideButton = (index: number) => {
        if (showSlide) {
            if (index < 0) {
                index = (slideContent as SlideComponentItem[]).length - 1;
            } else if (index >= (slideContent as SlideComponentItem[]).length) {
                index = 0;
            }
        } else {
            index = 0;
        }
        setCurrentImage(index);
    };
    const textArray = showSlide ? (slideContent as SlideComponentItem[])[currentImage].title.split((slideContent as SlideComponentItem[])[currentImage].titleCursive as string) : (slideContent as SlideComponentItem).title.split((slideContent as SlideComponentItem).titleCursive as string);
    const heroText = showSlide ? (slideContent as SlideComponentItem[])[currentImage].titleCursive : (slideContent as SlideComponentItem).titleCursive;
    const backgroundImageUrl = showSlide
        ? (slideContent as SlideComponentItem[])[currentImage].imageUrl || ''
        : (slideContent as SlideComponentItem).imageUrl || '';

    return (

        <div className="w-full md:h-[780px] h-[820px]">
            {isSignedIn ?
                <div
                    style={{ backgroundImage: `url(${backgroundImageUrl})` }}
                    className="h-full w-full object-cover bg-cover relative pt-[22px]"
                >
                    <div />
                    <div className="absolute top-0 left-0 w-full h-full bg-black opacity-0" />
                    <div className="" data-aos="fade-up" data-aos-duration="2000" data-aos-easing="ease-in-out-cubic">
                        <div className="flex justify-between items-center py-[20px] px-[34px] lg:px-[34px] xl:px-[74px] max-[840px]:hidden">
                            {!menuOpen ?
                                <img
                                    src="./images/hero-section/menu-open.png"
                                    alt="menu-open"
                                    onClick={handleOpenMenu}
                                    className="h-[44px] w-[49px] mt-[10px] mx-[20px] cursor-pointer hover:rotate-12"
                                /> : <div>
                                    <img
                                        src="./images/hero-section/menu-close.png"
                                        alt="menu-close"
                                        onClick={handleCloseMenu}
                                        className="h-[44px] w-[49px] mt-[10px] mx-[20px] cursor-pointer hover:rotate-12" />
                                </div>
                            }
                            <a href='/' className="md:pl-[11.7%]">
                                <img
                                    src="./images/logo.svg"
                                    alt="aqua-logo"
                                    className=" ml-[80px]"
                                />
                            </a>
                            <div className="flex items-center">
                                <button
                                    className="text-[#2789CA] bg-[#FFFFFF] mx-[20px] py-[16px] px-[30px] rounded-full text-[16px] leading-[24px] font-['Objektiv_Mk1_Trial'] font-[700] text-center lg:mr-[20px]"
                                >
                                    Explore Membership
                                </button>
                                <p className="text-[#2789CA] bg-[#FFFFFF] rounded-full text-[16px] leading-[17.6px] py-[8px] px-[16px] font-['Objektiv_Mk1_Trial'] font-[700] text-center">
                                    TA
                                </p>
                            </div>
                        </div>
                        <div className="flex justify-between items-center py-[20px] px-[34px] lg:px-[34px] xl:px-[74px] min-[840px]:hidden">
                            <a href='/' className="md:pl-[11.7%]">
                                <img
                                    src="./images/logo.svg"
                                    alt="aqua-logo"
                                    className=" ml-[5px] h-[56px] w-[176.71px]"
                                />
                            </a>
                            {!menuOpen ?
                                <img
                                    src="./images/hero-section/menu-open.png"
                                    alt="menu-open"
                                    onClick={handleOpenMenu}
                                    className="h-[30px] w-[33.41px] mx-[20px] cursor-pointer hover:rotate-12"
                                /> : <div>
                                    <img
                                        src="./images/hero-section/menu-close.png"
                                        alt="menu-close"
                                        onClick={handleCloseMenu}
                                        className="h-[30px] w-[33.41px] mx-[20px] cursor-pointer hover:rotate-12" />
                                </div>
                            }
                        </div>
                        <div className="justify-center text-center">
                            {!menuOpen && (
                                <div className="py-[13rem]">
                                   <p className="text-[#FFFFFF] font-[400] text-[48px] md:text-[64px] md:leading-[83.52px] leading-[62.64px] font-['Dante_MT_Std'] font-[400px] text-center ">
                                            {textArray.map((text, index) => {
                                                const words = text.split(' ');
                                                const wordPairs = [];

                                                for (let i = 0; i < words.length; i += 2) {
                                                    wordPairs.push(words.slice(i, i + 2).join(' ') + ' ');
                                                }

                                                return (
                                                    <span key={index}>
                                                        {wordPairs.map((wordPair, pairIndex) => (
                                                            <React.Fragment key={pairIndex}>
                                                                {wordPair}
                                                                {pairIndex < wordPairs.length - 1 && wordPair.endsWith('. ')  && <br />}
                                                            </React.Fragment>
                                                        ))}
                                                        {index < textArray.length - 1 && (
                                                            <span className="font-['Amalfi_Coast']">{heroText}</span>
                                                        )}
                                                    </span>
                                                );
                                            })}
                                        </p>

                                    <button
                                        className="text-[#2789CA] bg-[#FFFFFF] mx-[20px] py-[16px] px-[30px] my-[20px] rounded-full text-[16px] leading-[24px] font-['Objektiv_Mk1_Trial'] font-[700] text-center lg:mr-[20px] min-[840px]:hidden"
                                    >
                                        Explore Membership
                                    </button>
                                </div>
                            )}
                            {showSlide === true && !menuOpen && (
                                <div className="text-center">
                                    <button className={`w-[150px] h-[4px] ${currentImage === 0 ? 'bg-[#FFFFFF]' : 'bg-[#171717]/[.25]'} hover:bg-[#FFFFFF]`} onClick={() => slideButton(currentImage - 1)}></button>
                                    <button className={`w-[150px] h-[4px] ${currentImage === 1 ? 'bg-[#FFFFFF]' : 'bg-[#171717]/[.25]'} hover:bg-[#FFFFFF]`} onClick={() => slideButton(currentImage + 1)}></button>
                                </div>
                            )}
                        </div>
                        {menuOpen && (
                            <MenuComponent
                                onSignInClick={handleSignin}
                                onExploreMembershipClick={handleSignin}
                                menuOpen={menuOpen}
                                onClose={handleCloseMenu}
                                background={backgroundImageUrl}
                            />
                        )}
                    </div>
                </div>
                : <div
                    style={{ backgroundImage: `url(${backgroundImageUrl})` }}
                    className="h-full w-full object-cover bg-cover relative pt-[22px]"
                >
                    <div />
                    <div className="absolute top-0 left-0 w-full h-full bg-black opacity-0" />
                    <div className="" data-aos="fade-up" data-aos-duration="2000" data-aos-easing="ease-in-out-cubic">
                        <div className="flex justify-between items-center py-[20px] px-[34px] lg:px-[54px] xl:px-[74px] max-[840px]:hidden">
                            {!menuOpen ?
                                <img
                                    src="./images/hero-section/menu-open.png"
                                    alt="menu-open"
                                    onClick={handleOpenMenu}
                                    className="h-[44px] w-[49px] mt-[10px] mx-[20px] cursor-pointer hover:rotate-12"
                                /> : <div>
                                    <img
                                        src="./images/hero-section/menu-close.png"
                                        alt="menu-close"
                                        onClick={handleCloseMenu}
                                        className="h-[44px] w-[49px] mt-[10px] mx-[20px] cursor-pointer hover:rotate-12"
                                    />
                                </div>
                            }
                            <a href='/' className="md:pl-[11.7%]">
                                <img
                                    src="./images/logo.svg"
                                    alt="aqua-logo"
                                    className="ml-[80px]"
                                />
                            </a>
                            <div className="md:relative right-[13px]">
                                <button className="cursor-pointer text-[#FFFFFF] text-[16px] leading-[24px] font-['Objektiv_Mk1_Trial'] font-[700] text-center" onClick={handleSignin}>Sign In</button>
                                <button className="cursor-pointer text-[#2789CA] bg-[#FFFFFF] rounded-full py-[16px] px-[30px] mx-[20px] text-[16px] leading-[24px] font-['Objektiv_Mk1_Trial'] font-[700] text-center">Explore Membership</button>
                            </div>
                        </div>
                        <div className="flex justify-between items-center py-[20px] px-[34px] lg:px-[34px] xl:px-[74px] min-[840px]:hidden">
                            <a href='/' className="md:pl-[11.7%]">
                                <img
                                    src="./images/logo.svg"
                                    alt="aqua-logo"
                                    className=" ml-[5px] h-[56px] w-[176.71px]"
                                />
                            </a>
                            {!menuOpen ?
                                <img
                                    src="./images/hero-section/menu-open.png"
                                    alt="menu-open"
                                    onClick={handleOpenMenu}
                                    className="h-[30px] w-[33.41px] mx-[20px] cursor-pointer hover:rotate-12"
                                /> : <div>
                                    <img
                                        src="./images/hero-section/menu-close.png"
                                        alt="menu-close"
                                        onClick={handleCloseMenu}
                                        className="h-[30px] w-[33.41px] mx-[20px] cursor-pointer hover:rotate-12"
                                    />
                                </div>
                            }
                        </div>
                        <div className="justify-center text-center">
                            <div className="">
                                {!menuOpen && (
                                    <div className="pt-[7.7rem] pb-[8rem]">
                                        <p className="text-[#FFFFFF] font-[400] text-[48px] md:text-[64px] md:leading-[83.52px] leading-[62.64px] font-['Dante_MT_Std'] font-[400px] text-center ">
                                            {textArray.map((text, index) => {
                                                const words = text.split(' ');
                                                const wordPairs = [];

                                                for (let i = 0; i < words.length; i += 2) {
                                                    wordPairs.push(words.slice(i, i + 2).join(' ') + ' ');
                                                }

                                                return (
                                                    <span key={index}>
                                                        {wordPairs.map((wordPair, pairIndex) => (
                                                            <React.Fragment key={pairIndex}>
                                                                {wordPair}
                                                                {pairIndex < wordPairs.length - 1 && wordPair.endsWith('. ')  && <br />}
                                                            </React.Fragment>
                                                        ))}
                                                        {index < textArray.length - 1 && (
                                                            <span className="font-['Amalfi_Coast']">{heroText}</span>
                                                        )}
                                                    </span>
                                                );
                                            })}
                                        </p>

                                        <button
                                            className="text-[#2789CA] bg-[#FFFFFF] mx-[20px] py-[16px] px-[30px] my-[20px] rounded-full text-[16px] leading-[24px] font-['Objektiv_Mk1_Trial'] font-[700] text-center lg:mr-[20px] min-[840px]:hidden"
                                        >
                                            Explore Membership
                                        </button>
                                    </div>
                                )}
                                {showSlide === true && !menuOpen && (
                                    <div className="text-center">
                                        <button className={`w-[150px] h-[4px] ${currentImage === 0 ? 'bg-[#FFFFFF]' : 'bg-[#171717]/[.25]'} hover:bg-[#FFFFFF]`} onClick={() => slideButton(currentImage - 1)}></button>
                                        <button className={`w-[150px] h-[4px] ${currentImage === 1 ? 'bg-[#FFFFFF]' : 'bg-[#171717]/[.25]'} hover:bg-[#FFFFFF]`} onClick={() => slideButton(currentImage + 1)}></button>
                                    </div>
                                )}
                            </div>
                        </div>
                        {menuOpen && (
                            <MenuComponent
                                onSignInClick={handleSignin}
                                onExploreMembershipClick={handleSignin}
                                menuOpen={menuOpen}
                                onClose={handleCloseMenu}
                                background={backgroundImageUrl}
                            />
                        )}
                    </div>
                </div>
            }
        </div>
    );
}