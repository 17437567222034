import React, { useState } from "react";
import { TextContComponent } from "./TextContComponent";
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/24/outline'
import { BoxedCarouselProps } from "../Dtos/types/boxed-carousel";

export const BoxedCarouselComponent: React.FC<BoxedCarouselProps> = ({ imagePosition, carouselContent, darkMode, imageCarousel, title, button, bgColor }) => {
    const [currentCarousel, setCurrentCarousel] = useState(0);
    const totalExperiencesComponents = carouselContent.length;
    console.log({content:carouselContent});
    
    const slideButton = (direction: 'left' | 'right') => {
        if (direction === 'left') {
            setCurrentCarousel((currentCarousel + totalExperiencesComponents - 1) % totalExperiencesComponents);
        } else {
            setCurrentCarousel((currentCarousel + 1) % totalExperiencesComponents);
        }
    };

    return (
        <div className={`md:h-full h-auto w-full max-w-full ${bgColor === false ? 'bg-[#000000]/[.06]' : 'bg-[#E5EDF2]'}`}>
            <div className="w-full md:pb-[80px] h-auto">
                <p className="text-[#2789CA] text-center text-[16px] font-[700] leading-[25.5px] py-[2.5rem] font-[Objektiv_Mk1_Trial]">
                    {title}
                </p>
                {imagePosition === 'Right' ? (
                    <div className="md:flex md:relative w-full">
                        {carouselContent.map((carouselContents, index) => (
                            <div key={index} className='md:flex md:justify-center md:items-center md:w-1/4 hidden block'>
                                <TextContComponent
                                    text={carouselContents.description}
                                    title={carouselContents.title}
                                    darkMode={darkMode}
                                    cardPosition={carouselContents.cardPosition}
                                    button={button}
                                    link={carouselContents.link}
                                />
                            </div>
                        ))}
                        {carouselContent.map((carouselContents, index) => (
                            <div key={index} className='relative'>
                                <div className="md:right-0 z-[1] w-full ">
                                    <img src={carouselContents.mediaUrl} className="w-[67.625rem] md:h-[31.6875rem] object-cover" alt="bg-img" />
                                </div>
                            </div>
                        ))}
                        {carouselContent.map((carouselContents, index) => (
                            <div key={index} className='mx-[1.5rem] -mt-[2.5rem] md:hidden md:block flex justify items-center'>
                                <TextContComponent
                                    text={carouselContents.description}
                                    title={carouselContents.title}
                                    darkMode={darkMode}
                                    cardPosition={carouselContents.cardPosition}
                                    button={button}
                                    link={carouselContents.link}
                                />
                            </div>
                        ))}
                    </div>
                ) : imageCarousel === true ? (
                    <div className="md:flex md:relative w-full ">
                        <div className='relative'>
                            <div className="md:left-0 flex-end z-[1] w-full  max-[768px]:hidden max-[768px]:block">
                                <img src={carouselContent[currentCarousel].mediaUrl} className="w-[67.625rem] md:h-[31.6875rem] object-cover" alt="bg-img" />
                            </div>
                        </div>
                        <div className='md:flex md:justify-center md:items-center md:w-1/4 hidden block'>
                            <div className="absolute left-[56%] top-0 bottom-0 flex items-center">
                                {/* <ChevronLeftIcon
                                    className={`h-[50px] w-[50px] ${currentCarousel === 0 ? 'text-[#FFFFFF]' : 'text-[#FFFFFF]'} hover:text-[#2789CA]`}
                                    onClick={() => slideButton('left')}
                                /> */}
                                
                                <img src="/images/slide-arrow-icon.svg" alt="left-arrow-icon" onClick={() => slideButton('left')} className={`${currentCarousel === 0 ? 'text-[#FFFFFF]' : 'text-[#FFFFFF]'} hover:text-[#2789CA] cursor-pointer`}/>
                            </div>
                            <div className='md:flex md:justify-center md:items-center md:w-1/4 hidden block'>
                                <TextContComponent
                                    text={carouselContent[currentCarousel].description}
                                    title={carouselContent[currentCarousel].title}
                                    darkMode={darkMode}
                                    cardPosition={carouselContent[currentCarousel].cardPosition}
                                    button={button}
                                    link={carouselContent[currentCarousel].link}
                                />
                            </div>
                            <div className="absolute right-[9%] max-[1290px]:right-[3%]  max-[1230px]:right-0 top-0 bottom-0 flex items-center" >
                                {/* <ChevronRightIcon
                                    className={`h-[50px] w-[50px] ${currentCarousel === totalExperiencesComponents - 1 ? 'text-[#FFFFFF]' : 'text-[#FFFFFF]'} hover:text-[#2789CA]`}
                                    onClick={() => slideButton('right')}
                                /> */}  
                                <img src="/images/slide-arrow-icon.svg" alt="right-arrow-icon" onClick={() => slideButton('right')} className={`rotate-180 ${currentCarousel === totalExperiencesComponents - 1 ? 'fill-[#FFFFFF]' : 'fill-[#FFFFFF]'} cursor-pointer`}/>
                            </div>
                        </div>
                        {carouselContent.map((carouselContents, index) => (
                            <div key={index}>
                                <div className="relative">
                                    <div className="md:left-0 flex-end z-[1] w-full  min-[768px]:hidden min-[768px]:block">
                                        <img src={carouselContents.mediaUrl} className="w-[67.625rem] md:h-[31.6875rem] object-cover" alt="bg-img" />
                                    </div>
                                </div>
                                <div className='mx-[1.5rem] -mt-[2.5rem] md:hidden md:block flex justify items-center'>
                                <TextContComponent
                                    text={carouselContents.description}
                                    title={carouselContents.title}
                                    darkMode={darkMode}
                                    cardPosition={carouselContents.cardPosition}
                                    button={button}
                                    link={carouselContents.link}
                                />
                                </div>
                            </div>
                        ))}
                    </div>
                ) : (
                    <div className="md:flex md:relative w-full">
                        {carouselContent.map((carouselContents, index) => (
                            <div key={index} className='relative'>
                                <div className="md:left-0 flex-end z-[1] w-full ">
                                    <img src={carouselContents.mediaUrl} className="w-[67.625rem] md:h-[31.6875rem] object-cover" alt="bg-img" />
                                </div>
                            </div>
                        ))}
                        {carouselContent.map((carouselContents, index) => (
                            <div key={index} className='md:flex md:justify-center md:items-center md:w-1/4 hidden block'>
                                <TextContComponent
                                    text={carouselContents.description}
                                    title={carouselContents.title}
                                    darkMode={darkMode}
                                    cardPosition={carouselContents.cardPosition}
                                    button={button}
                                    link={carouselContents.link}
                                />
                            </div>
                        ))}
                        {carouselContent.map((carouselContents, index) => (
                            <div key={index} className='mx-[1.5rem] -mt-[2.5rem] md:hidden md:block flex justify items-center'>
                                <TextContComponent
                                    text={carouselContents.description}
                                    title={carouselContents.title}
                                    darkMode={darkMode}
                                    cardPosition={carouselContents.cardPosition}
                                    button={button}
                                    link={carouselContents.link}
                                />
                            </div>
                        ))}
                    </div>

                )}
            </div>
        </div>
    );
};
