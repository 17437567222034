import { useEffect, useState } from "react";
import { OurTeamResponse } from "../../Dtos/ApiResponse/our-team-response";
import { SlideComponentItem } from "../../Dtos/types/hero";
import { ApiManager } from "../../Utilities/api-manager";
import Layout from "../Common/page-layout";
import { Loader } from "../../Utilities/loader";
import { StylizedTextComponent } from "../../Components/StylizedTextComponent";
import FounderCardLeft from "../../Components/founder-card-left/founder-card-left";
import FounderCardRight from "../../Components/founder-card-right/founder-card-right";
import { PartnerComponent } from "../../Components/partners-component/partners-component";

const OurTeamIndex: React.FC = () => {
    const [data, setData] = useState<OurTeamResponse | null>(null);
    const [heroData, setHeroData] = useState<SlideComponentItem>({ imageUrl: "", title: "" });
    const fetchData = async () => {
        let response = await ApiManager<any, OurTeamResponse>("/page/our-team", "GET");
        if (response) {
            let hero: SlideComponentItem = {
                imageUrl: response.data.HeroSection.imageUrl as string,
                title: response.data.HeroSection.title as string,
                titleCursive: response.data.HeroSection.titleCursive
            };
            setData(response.data);
            setHeroData(hero);
        }
    };

    useEffect(() => {
        fetchData();
    }, []);
    return (
        <>
            {
                data ? 
                <Layout heroProp={{ showSlide: false, slideContent: heroData }}>
                    <StylizedTextComponent 
                    focusText={data.CursiveContentSection.contentCursive as string}
                    text={data.CursiveContentSection.content as string} />
                    {
                        data.TeamsSection.map((_data, index) => (
                            (index+1) % 2 === 0? 
                            <FounderCardRight 
                            buttontext="Read More"
                            company={_data.company}
                            image={_data.imageUrl}
                            imageMobile={_data.imageUrl}
                            link={_data.link}
                            name={_data.name}
                            text={_data.description}
                            title={_data.designation} />
                            :
                            <FounderCardLeft 
                            bottommargin="0px"
                            buttontext="Read More"
                            company={_data.company}
                            image={_data.imageUrl}
                            imageMobile={_data.imageUrl}
                            link={_data.link}
                            name={_data.name}
                            text={_data.description}
                            title={_data.designation} 
                            hidebutton={false}
                            width="100%" />
                            ))
                    }
                   <PartnerComponent images={data.PartnerSection} />
                </Layout> : <Loader />
            }

        </>
    );
};

export default OurTeamIndex;