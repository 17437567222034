import React, { useEffect, useState } from "react";
import AOS from 'aos';
import 'aos/dist/aos.css';
import { RoomFeaturesProps } from "../../Dtos/types/room-features-interface";
import "./room-features.css"
import bullet from "../../images/bullet.png"
import { Features } from "@headlessui/react/dist/utils/render";


export default function RoomFeatures(props:RoomFeaturesProps) {
    
    useEffect(() => {
        AOS.init();
      }, [])

      const features=props.featuresArray;
      console.log({features:features});
      
    
    return (props.featuresArray && 
        <>
        <div className="room-features">
            <div className="room-features-web pt-10 pb-20">
                <p className="text-center room-features-title mb-12 mt-5">{props.title}</p>
                <div  className="grid grid-cols-3 auto-rows-auto grid-flow-row">
                {features.map((feat, index) => (
                    <div key={index} data-aos="fade-left" className="room-features-bullet-div mb-5"><img className="room-features-bullet-img" src={props.image} alt="" /><p className="room-features-bullet-text">{feat}</p></div>
                ))}
                </div>
            </div>
            

            {/* mobile view */}
            <div className="room-features-mobile pt-10 pb-20 pr-5 pl-5">
                <p className="text-center room-features-title mb-12 mt-5">{props.title}</p>
                <div  className="grid grid-cols-1  auto-rows-auto grid-flow-row room-features-mobile-grid">
                {features.map((feat, index) => (
                    <div key={index} className="room-features-bullet-div mb-5"><img className="room-features-bullet-img" src={props.image} alt="" /><p className="room-features-bullet-text">{feat}</p></div>
                ))}
                </div>
            </div>
            {/* mobile view */}


        </div>
        </>
    );
}