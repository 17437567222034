import React, { useEffect, useState } from "react";
import { DineAndDrinkResponse } from "../../Dtos/ApiResponse/dine-and-drink-response";
import { ApiManager } from "../../Utilities/api-manager";
import Layout from "../Common/page-layout";
import {SlideComponentItem } from "../../Dtos/types/hero";
import { StylizedTextComponent } from "../../Components/StylizedTextComponent";
import { MenuSectionComponent } from "../../Components/MenuSectionComponent";
import { menuHighlightItem } from "../../Dtos/types/menu-section";
import { ImageAndTextFullWidthComponent } from "../../Components/ImageAndTextFullWidthComponent";
import AqualinaExudes from "../../Components/aqualina-exudes/aqualina-exudes";
import PrivateEvents from "../../Components/private-events/private-events";
import { Loader } from "../../Utilities/loader";
import RightImageTextComponent from "../../Components/right-image-text-component/right-image-text-component";



const DineAndDrinkIndex: React.FC = () => {
  const [data, setData] = useState<DineAndDrinkResponse | null>(null);
  const [heroData, setHeroData] = useState<SlideComponentItem>({ imageUrl: "", title: "" });
  const fetchData = async () => {
    let response = await ApiManager<any, DineAndDrinkResponse>("/page/dine-and-drink", "GET");
    if (response) {
      let hero: SlideComponentItem = {
        imageUrl: response.data.HeroSection.imageUrl,
        title: response.data.HeroSection.title,
        titleCursive: response.data.HeroSection.titleCursive
      };
      setData(response.data);
      setHeroData(hero);
    }
  };

  useEffect(() => {
    fetchData();
  }, []); // Empty dependency array ensures the effect runs once after the initial render


  return (
    <>
    {
      data? <Layout heroProp={{ showSlide: false, slideContent: heroData }}>
      <StylizedTextComponent 
      focusText={data?.CursiveContentSection.contentCursive as string} 
      text={data?.CursiveContentSection.content as string} />
      <RightImageTextComponent 
      content={data.DetailsSection.details} 
      excerpt={data.DetailsSection.excerpt} 
      imageUrl={data.DetailsSection.imageUrl} />
      <MenuSectionComponent
        menuHighlights={data?.MenuSection.menuHighlights as any as menuHighlightItem[]}
        menuSummary={data?.MenuSection.menuSummary as string[]}
      />
      <ImageAndTextFullWidthComponent
        video={undefined}
        image={data?.CardSetion.imageUrl}
        playButton={undefined}
      />
      <StylizedTextComponent text={data?.CardSetion.content as string} focusText='' />
      <AqualinaExudes caption={data?.CuisineSection.excerpt as string}
        imageOne={data?.CuisineSection.images[0]} imageOneMobile={data?.CuisineSection.images[0]}
        imageTwo={data?.CuisineSection.images[1]} imageTwoMobile={data?.CuisineSection.images[1]}
        imageThree={data?.CuisineSection.images[2]} imageThreeMobile={data?.CuisineSection.images[2]} />
      <PrivateEvents backgroundimage={data?.PrivateEventSection.imageUrl}
        buttontext="View Event Calendar"
        altBodyText=""
        bodytext={data?.PrivateEventSection.title as string}
        link={data?.PrivateEventSection.link as string}
        mobilebackgroundimage={data?.PrivateEventSection.imageUrl}
        title="PRIVATE EVENTS"
      />
    </Layout>: <Loader/>
    }
    </>
    
  );
};

export default DineAndDrinkIndex;