import React, { useEffect, useState } from "react";
import "./room-cards.css";
import AOS from 'aos';
import 'aos/dist/aos.css';
import { RoomCardsProps } from "../../Dtos/types/room-cards-interface";
import { Link } from "react-router-dom";


export default function RoomCards(props:RoomCardsProps) {
    
    useEffect(() => {
        AOS.init();
      }, [])

      const cards = props.cardArray;
    
    return (
        <>
        <div data-aos="fade-up" className="room-cards mb-10">

            <p className="mb-12 text-center room-cards-title">{props.title}</p>
            <div className="room-cards-web">
                <div className="grid grid-cols-3 gap-12 room-cards-grid">
                {cards.map((list: { imageUrl: string | undefined; title: string | number | boolean | React.ReactElement<any, string | React.JSXElementConstructor<any>> | Iterable<React.ReactNode> | React.ReactPortal | null | undefined; description: string | number | boolean | React.ReactElement<any, string | React.JSXElementConstructor<any>> | Iterable<React.ReactNode> | React.ReactPortal | null | undefined; }, i: React.Key | null | undefined)=>(
                    <div key={i}>
                        <img data-aos="flip-right" className="room-card-image" src={list.imageUrl} alt="" />
                        <p data-aos="fade-up" className="room-card-title">{list.title}</p>
                        <p data-aos="fade-up" className="room-card-text">{list.description}</p>
                        <div className="mt-5">
                            <Link to={`/room-details?title=${list.title}`}>
                                <button className="rounded-full bg-white px-4 py-2.5 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 room-cards-button room-cards-button-set">{props.buttonText}</button>
                            </Link>
                        </div>
                    </div>))}
                </div>
            </div>
            

            {/* mobile view */}
                <div className="room-cards-mobile">
                {cards.map((list: { imageUrl: string | undefined; title: string | number | boolean | React.ReactElement<any, string | React.JSXElementConstructor<any>> | Iterable<React.ReactNode> | React.ReactPortal | null | undefined; description: string | number | boolean | React.ReactElement<any, string | React.JSXElementConstructor<any>> | Iterable<React.ReactNode> | React.ReactPortal | null | undefined; }, i: React.Key | null | undefined)=>(
                    <div key={i} data-aos="fade-up">
                        <img className="room-card-image" src={list.imageUrl} alt="" />
                        <p className="room-card-title mt-3">{list.title}</p>
                        <p className="room-card-text mt-3 mb-3">{list.description}</p>
                        <div className="mb-10">
                            <Link to={`/room-details?title=${list.title}`}>
                                <button className="rounded-full bg-white px-4 py-2.5 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 room-cards-button">{props.buttonText}</button>
                            </Link>
                        </div>
                    </div>))}
                </div>
            {/* mobile view */}

        </div>
        </>
    );
}