import React, { useEffect, useState } from "react";
import AOS from 'aos';
import 'aos/dist/aos.css';
import "./shop-coming-soon.css"
import { ShopComingSoonProps } from "../../Dtos/types/shop-coming-soon-interface";
import { ApiManager } from "../../Utilities/api-manager";
import fire from "../../Utilities/alert";
import { MessageType } from "../../Enums/message-type";
import { Loader } from "../../Utilities/loader";


export default function ShopComingSoon(props:ShopComingSoonProps) {

    const [payload, setPayload] = useState({email:""})
    const [loading, setLoading]  = useState<Boolean>(false);
    
    useEffect(() => {
        AOS.init();
      }, [])

      const register =async()=>{
        setLoading(true);
        const response = await ApiManager<any, any>("/page/subscribe", "POST", payload);
        console.log({response:response});
        if(response){
            fire(
                MessageType.success,
                "Thank you for subscribing"
            )
            setLoading(false);
        }else{
            setLoading(false);
        }
      }

      const handleInputChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const { name, value } = event.target;
        setPayload({
          ...payload,
          [name]: value,
        });
      };

    
    return (
        <>
        <div className="shop-coming-soon">
            <div className="shop-coming-soon-web">
                <div className="grid grid-cols-2">
                    <div className="shop-coming-soon-leftgrid">
                        <a href="/">
                        <img data-aos="fade-right" className="shop-coming-soon-logo" src={props.logo} alt="" />
                        </a>
                        
                        <p data-aos="fade-right" className="shop-coming-soon-label">{props.label}</p>
                        <div data-aos="fade-right">
                            <p className="shop-coming-soon-caption caption-normal">{props.caption1}</p>
                            <span className="shop-coming-soon-caption caption-accent">{props.caption2}</span>
                            <span>{" "}</span>
                            <span className="shop-coming-soon-caption caption-normal">{props.caption3}</span>
                        </div>
                        <div data-aos="fade-right" className="coming-soon-form">
                            <div className="shop-coming-soon-form" style={{position:"relative"}}>
                                <input onChange={handleInputChange} className="shop-coming-soon-input" type="email" name="email"
                                placeholder="Your email address..." />
                                {
                                    loading? <Loader />
                                    :
                                <button onClick={()=>register()} className="shop-coming-soon-button">{props.buttonText}</button>

                                }
                            </div> 
                        </div>
                        <div className="botton-sect">
                            <div className="shop-coming-soon-socials">
                                <img className="social-icons-set" src={props.facebook} alt="" />
                                <img className="social-icons-set" src={props.insta} alt="" />
                                <img className="social-icons-set" src={props.whatsapp} alt="" />
                                <img className="social-icons-set" src={props.tiktok} alt="" />
                            </div>
                            <div className="mt-2">
                                <p className="copywrite-text">{props.tMark}</p>
                            </div>
                        </div>
                    </div>
                    <div>
                        <img data-aos="fade-left" className="shop-coming-soon-mainimage" src={props.mainImage} alt="" />
                    </div>

                </div>
                
            </div>
            

            {/* mobile view */}
            <div className="shop-coming-soon-mobile">
                <div className="shop-coming-soon-leftgrid">
                        <img data-aos="fade-up" className="shop-coming-soon-logo" src={props.logo} alt="" />
                        <p data-aos="fade-up" className="shop-coming-soon-label">{props.label}</p>
                        <div data-aos="fade-up">
                            <p className="shop-coming-soon-caption caption-normal">{props.caption1}</p>
                            <span className="shop-coming-soon-caption caption-accent">{props.caption2}</span>
                            <span>{" "}</span>
                            <span className="shop-coming-soon-caption caption-normal">{props.caption3}</span>
                        </div>
                        <div data-aos="fade-up" className="coming-soon-form">
                            <div className="shop-coming-soon-form" style={{position:"relative"}}>
                                <input onChange={handleInputChange} className="shop-coming-soon-input" type="email"
                                placeholder="Your email address..." />
                                <button onClick={()=>register()} className="shop-coming-soon-button">{props.buttonText}</button>
                            </div>
                        </div>
                        <div className="botton-sect mb-20 mt-40">
                            <div data-aos="fade-up" className="shop-coming-soon-socials">
                                <img className="social-icons-set" src={props.facebook} alt="" />
                                <img className="social-icons-set" src={props.insta} alt="" />
                                <img className="social-icons-set" src={props.whatsapp} alt="" />
                                 <img className="social-icons-set" src={props.tiktok} alt="" />
                            </div>
                            <div data-aos="fade-up" className="mt-5">
                                <p className="copywrite-text">{props.tMark}</p>
                            </div>
                        </div>
                </div>
                <div>
                    <img data-aos="fade-up" className="shop-coming-soon-mainimage" src={props.mainImage} alt="" />
                </div>
            </div>
            {/* mobile view */}


        </div>
        </>
    );
}