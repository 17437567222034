import { useState, ChangeEvent, MouseEvent } from "react";
import { ApiManager } from "../Utilities/api-manager";
import { Loader } from "../Utilities/loader";
import fire from "../Utilities/alert";
import { MessageType } from "../Enums/message-type";



export const SubscriberComponent = () => {
    const [email, setEmail] = useState('');
    const [formData, setFormData] = useState('');
    const [loading, setLoading] = useState<Boolean>(false);

    const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
        setEmail(event.target.value);
    };

    const handleSubmit = async (event: MouseEvent) => {
        const emailPattern = /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}$/;

        if (!emailPattern.test(email)) {
            fire(MessageType.error, 'Invalid email address')
            setLoading(false);
            setEmail('')
            return;
        }

        setLoading(true);
        event.preventDefault();
        const data = {
            email: email,
        };

        try {
            const response = await ApiManager('/page/subscribe', 'POST', JSON.stringify(data));
            console.log('success', response);
            if (response?.message === 'Success') {
                fire(MessageType.success, response.message)
                setFormData(email);
                setLoading(false);
            }
        } catch (error) {
            console.error("Error:", error);
            setLoading(false);
        }
        setEmail('')
    };


    return (
        <div className="flex items-center justify-center h-[291px] w-full bg-[#2789CA] px-[12px]">
            <div className="text-[#FFFFFF] text-center" data-aos="fade-up" data-aos-duration="2000" data-aos-easing="ease-in-out-cubic">
                <p className="font-[700] md:text-[16px] text-[14px] leading-[25.5px] pb-[2.5rem] md:pb-[0rem] text-center font-['Objektiv_Mk1_Trial']">STAY CONNECTED</p>
                <p className="font-[400] md:text-[40px] text-[32px] md:leading-[42.4px] leading-[33.92px] text-center font-['Dante_MT_Std'] py-[18px]">Join our Inner Circle</p>
                <div className="flex items-center bg-[#FFFFFF] md:h-[84px] h-[72px] rounded-[27px] w-[290px] md:px-[30px] px-[15px] md:w-[625px]">
                    <input
                        onChange={handleChange}
                        type="email"
                        id="notify-input-field"
                        className="placeholder-[#3D3D3D] text-[#3D3D3D] border-none w-full text-[16px] md:text-[18px] leading-[17.6px] md:leading-[19.8px] font-['Objektiv_Mk1_Trial'] font-[400]"
                        name='email'
                        placeholder="Enter your email Address"
                        required
                        value={email}
                    />
                    {
                        loading ?
                            <Loader />
                            :
                            <img src="./images/arrow-right.svg" alt="submit-arrow" onClick={handleSubmit} className="cursor-pointer hover:translate-x-0.5" />
                    }
                </div>
            </div>
        </div>
    );
}


