import { useEffect, useState } from "react";
import { ExperienceResponse } from "../../Dtos/ApiResponse/experience-response";
import { ApiManager } from "../../Utilities/api-manager";
import { SlideComponentItem } from "../../Dtos/types/hero";
import Layout from "../Common/page-layout";
import { StylizedTextComponent } from "../../Components/StylizedTextComponent";
import Experience from "../../Components/experience/experience";
import { Loader } from "../../Utilities/loader";
import PrivateEvents from "../../Components/private-events/private-events";

const ExperienceIndex: React.FC = () => {
    const [data, setData] = useState<ExperienceResponse | null>(null);
    const [heroData, setHeroData] = useState<SlideComponentItem>({ imageUrl: "", title: "" });
    const fetchData = async () => {
        let response = await ApiManager<any, ExperienceResponse>("/page/experience", "GET");
        if (response) {
            let hero: SlideComponentItem = {
                imageUrl: response.data.HeroSection.imageUrl,
                title: response.data.HeroSection.title,
                titleCursive: response.data.HeroSection.titleCursive
            };
            setData(response.data);
            setHeroData(hero);
        }
    };

    useEffect(() => {
        fetchData();
    }, []);
    return (
        <>
            {
                data ?
                    <Layout heroProp={{ showSlide: false, slideContent: heroData }}>
                        <StylizedTextComponent
                            focusText={data?.CursiveContentSection.contentCursive as string}
                            text={data?.CursiveContentSection.content as string} />
                        <Experience captionOne={data?.SpaAndWelnessSection.title as string}
                            captionTwo=""
                            fullImage={true}
                            fullWidth={true}
                            imageOne={data?.SpaAndWelnessSection.imageUrl}
                            imageTwo=""
                            textOne={data?.SpaAndWelnessSection.content as string}
                            textTwo="" />
                        <Experience captionOne={data?.EntertainmentOutdoorSection.cards[0].title as string}
                            captionTwo={data?.EntertainmentOutdoorSection.cards[1].title as string}
                            fullImage={false}
                            fullWidth={false}
                            imageOne={data?.EntertainmentOutdoorSection.cards[0].imageUrl}
                            imageTwo={data?.EntertainmentOutdoorSection.cards[1].imageUrl}
                            textOne={data?.EntertainmentOutdoorSection.cards[0].content as string}
                            textTwo={data?.EntertainmentOutdoorSection.cards[1].content as string} />
                        <Experience captionOne={data?.PrivateWineTastingSection.title as string}
                            captionTwo=""
                            fullImage={false}
                            fullWidth={true}
                            imageOne={data?.PrivateWineTastingSection.imageUrl}
                            imageTwo=""
                            textOne={data?.PrivateWineTastingSection.content as string}
                            textTwo="" />
                        <Experience captionOne={data?.FoundersCulinarySection.title as string}
                            captionTwo=""
                            fullImage={true}
                            fullWidth={true}
                            imageOne={data?.FoundersCulinarySection.imageUrl}
                            imageTwo=""
                            textOne={data?.FoundersCulinarySection.content as string}
                            textTwo="" />
                        <Experience captionOne={data?.ArtCultureRestSection.cards[0].title as string}
                            captionTwo={data?.ArtCultureRestSection.cards[1].title as string}
                            fullImage={false}
                            fullWidth={false}
                            imageOne={data?.ArtCultureRestSection.cards[0].imageUrl}
                            imageTwo={data?.ArtCultureRestSection.cards[1].imageUrl}
                            textOne={data?.ArtCultureRestSection.cards[0].content as string}
                            textTwo={data?.ArtCultureRestSection.cards[1].content as string} />
                        <Experience captionOne={data?.CinemaSection.title as string}
                            captionTwo=""
                            fullImage={false}
                            fullWidth={true}
                            imageOne={data?.CinemaSection.imageUrl}
                            imageTwo=""
                            textOne={data?.CinemaSection.content as string}
                            textTwo="" />
                        <PrivateEvents backgroundimage={data?.PrivateEventSection.imageUrl}
                            buttontext="View Event Calendar"
                            altBodyText=""
                            bodytext={data?.PrivateEventSection.title as string}
                            link={data?.PrivateEventSection.link as string}
                            mobilebackgroundimage={data?.PrivateEventSection.imageUrl}
                            title="PRIVATE EVENTS"
                        />
                    </Layout> : <Loader />
            }
        </>

    );
};
export default ExperienceIndex;