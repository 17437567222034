import React from 'react';

export const ContactDetails = () => {
    return (
        <div className='bg-[#E5EDF2] font-["Objektiv_Mk1_Trial"] m-[0] p-[0]'>
            <div className='flex flex-col justify-center items-center pb-[1.5rem]'>
                <h1 className='text-center text-[#2789CA] font-[700] text-[1rem] leading-[1.59375rem]'>
                    CONTACT DETAILS
                </h1>
            </div>
            <div>
            <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3964.1317007498874!2d3.099431074836187!3d6.505007823378299!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x103b8274b3722de9%3A0x98d84a958c85eb45!2sIlashe%20Rd%2C%20112102%2C%20Auaba%2C%20Ogun%20State!5e0!3m2!1sen!2sng!4v1697447355178!5m2!1sen!2sng"
                width="100%"
                height="450"
                allowFullScreen={true}
                loading="lazy"
                referrerPolicy="no-referrer-when-downgrade"
                style={{
                    border: '0',
                    filter: 'grayscale(0) invert(1) brightness(90%) sepia(70%) hue-rotate(180deg) saturate(200%)', 
                    color: 'white', 
                  }}
                title="Google Maps"
            ></iframe>
            </div>
            <div className='md:flex md:justify-between md:items-center md:p-[2.5rem] md:pt-[0] p-[1rem]'>
                <div>
                    <h1 className='text-[#2789CA] text-[1rem] font-[500] leading-[1.59375rem] px-[0.5rem]'>Location</h1>
                    <p className='text-[#505457] text-[1.125rem] font-[500] leading-[2rem] px-[0.5rem]'>Aqualina Beach, corporate Office, 5 ilashe road, Lagos, NG 102351</p>
                </div>
                <div>
                    <h1 className='text-[#2789CA] text-[1rem] font-[500] leading-[1.59375rem] px-[0.5rem]'>Email Address</h1>
                    <p className='text-[#505457] text-[1.125rem] font-[500] leading-[2rem] px-[0.5rem]'>info@aqualina.com</p>
                </div>
                <div>
                    <h1 className='text-[#2789CA] text-[1rem] font-[500] leading-[1.59375rem] px-[0.5rem]'>Phone Number</h1>
                    <p className='text-[#505457] text-[1.125rem] font-[500] leading-[2rem] px-[0.5rem]'>+234812837622</p>
                </div>
            </div>
        </div>
    );
};
