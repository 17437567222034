import axios, { AxiosResponse } from "axios";
import { StandardResponse } from "../Dtos/standard-response";
import { env } from "../Environments/environment";
import fire from "./alert";
import { MessageType } from "../Enums/message-type";

export async function ApiManager<T1, T2>(
    Url: string,
    Method: string,
    Data?: T1,
    timeoutOverride?: number,
    silent?: string,
  
  ): Promise<StandardResponse<T2> | null> {
    const defaultValue: StandardResponse<T2> | null= null;
    if (localStorage) {
      if (localStorage.getItem("token")) {
        axios.defaults.headers.common["Authorization"] = `Bearer ${env.token}`;
        axios.defaults.headers.common["Content-Type"] = `application/json`;
      }
    }
  
    axios.defaults.headers.common["Content-Type"] = `application/json`;
    axios.defaults.headers.common["Authorization"] = `Bearer ${env.token}`;
    axios.interceptors.response.use(
      (response) => {
  
        if (response?.data?.authorization) {
          localStorage.setItem("token", response.data.authorization);
        }
        return response;
      },
      (error) => {
        return error.response;
      }
    );
  
    const baseUrl = env.BaseUrl
  
    const response: AxiosResponse<StandardResponse<T2>> = await axios({
      method: Method,
      url: baseUrl + Url.trim(),
      data: Data,
      timeout: timeoutOverride || env.ApiRequestTimeout
    });
    if (response) {
  
      if (!response.status || response.status === 0) {
        if (!silent)
          fire(
            MessageType.error,
            "Sorry it seems you are not connected to internet. Please check you network connection and try again"
          );
        return defaultValue;
      }
      if (response.status === 401 || response.statusText === "Unauthorized") {
        let message =
          "Sorry there is an error sending your request at the moment please try again later";
        if (response.data) {
          message = `${response.data.message}`;
        }
        if (!silent) fire(MessageType.error, message);
        return defaultValue;
      }
      if (response.status >= 400 && response.status < 500) {
        let message =
          "Sorry your request is invalid. please check your request and try again";
        if (response.data) {
          if (response.data.message) {
            message = `${response.data.message}`;
          }
        }
        if (!silent) fire(MessageType.warning, message);
        return defaultValue;
      }
      if (response.status >= 500) {
        let message =
          "Sorry your request cannot be processed at this moment please try again later";
        if (response.data) {
          message = `${response.data.message}`;
        }
        if (!silent) fire(MessageType.error, message);
        return defaultValue;
      }
    } else {
      if (!silent) {
        fire(
          MessageType.error,
          "Your request generated an error. Please check your network connection"
        );
      }
    }
    if(response && response.data && !response.data.status){
      fire(
        MessageType.error,
        response.data.message
      );
      return defaultValue;
    }
    return !response
      ? defaultValue
      : response.data
  
  }