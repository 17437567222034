import React, { useEffect, useState } from "react";
import ShopComingSoon from "../../Components/shop-coming-soon/shop-coming-soon";
import shoplogo from "../../images/shoplogo.png";
import shopmainimg from "../../images/shopmainimage.png";
import instagram from "../../images/insta.png";
import facebook from "../../images/facebook.png";
import tiktok from "../../images/tiktok.png";
import whatsapp from "../../images/whatsapp.png";
import { ToastContainer } from "react-toastify";

export default function ShopPage() {
    
    
    
    return (
        <>
        
        <ShopComingSoon
        caption1="We are"
        caption2="Coming"
        caption3="Soon!"
        logo={shoplogo}
        insta={instagram}
        facebook={facebook}
        tiktok={tiktok}
        whatsapp={whatsapp}
        mainImage={shopmainimg}
        tMark="© 2023 Aqualina Beach House.  All rights reserved"
        label="SHOP"
        buttonText="Notify me" />
        </>
    );
}