import React, { useEffect } from "react";
import "./right-image-text-component.css";
import AOS from 'aos';
import 'aos/dist/aos.css';
import { RightImageTextComponentProp } from "../../Dtos/right-image-text-component-prop";


export default function RightImageTextComponent(props:RightImageTextComponentProp) {
    
    useEffect(() => {
        AOS.init();
      }, [])
    const textArray = props.content.split(/\r?\ n/);
    return (
        <>
        <div className="room-detail">
            <div className="room-detail-web pb-28 pt-20">
                <div className="grid grid-cols-2 gap-20 room-detail-grid">
                <div className="room-detail-column2 md:pl-14">
                        <p data-aos="fade-left" className="room-detail-title">{props.excerpt}</p>
                        {
                            textArray.map((text, index) => (
                                <p data-aos="fade-left" className="room-detail-text">{text}</p>
                            ))
                        }
                       
                    </div>
                    <div className="">
                        <img className="room-detail-image" src={props.imageUrl} alt="" />
                    </div>
                </div>
            </div>
            

            {/* mobile view */}
            <div className="room-detail-mobile">
                <div data-aos="fade-up">
                    <img style={{width:"100%"}} src={props.imageUrl} alt="" />
                </div>
                <p className="room-detail-title">{props.excerpt}</p>
                {
                            textArray.map((text, index) => (
                                <p data-aos="fade-left" className="room-detail-text">{text}</p>
                            ))
                        }
               
                
            </div>
            {/* mobile view */}


        </div>
        </>
    );
}