import { useEffect, useState } from "react";
import { SingleRoomResponse } from "../../Dtos/ApiResponse/single-room-response";
import { SlideComponentItem } from "../../Dtos/types/hero";
import {useSearchParams } from 'react-router-dom';
import { ApiManager } from "../../Utilities/api-manager";
import Layout from "../Common/page-layout";
import { Loader } from "../../Utilities/loader";
import { StylizedTextComponent } from "../../Components/StylizedTextComponent";
import RoomDetail from "../../Components/room-detail/room-detail";
import bullet from "../../images/bullet.png";
import RoomFeatures from "../../Components/room-features/room-features";
import { FullWidthGalleryCarouselComponent } from "../../Components/full-width-gallery-carousel/full-width-gallery-carousel";
import { BoxedCarouselComponent } from "../../Components/BoxedCarouselComponent";


const SingleRoom:React.FC = () => {
    const [data, setData] = useState<SingleRoomResponse | null>(null);
    const [heroData, setHeroData] = useState<SlideComponentItem>({ imageUrl: "", title: "" });
    const [searchParams, setSearchParams] = useSearchParams();
    const [title, setTitle] = useState<string>("");
    
    const fetchData = async (title:string) => {
        let response = await ApiManager<any, SingleRoomResponse>(`/page/room?title=${title}`, "GET");
        if (response) {
          let hero: SlideComponentItem = {
            imageUrl: response.data.HeroSection.imageUrl,
            title: response.data.HeroSection.title,
            titleCursive: response.data.HeroSection.titleCursive
          };
          setData(response.data);
          setHeroData(hero);
        }
      };

      useEffect(() => {
        const params: string = searchParams.get("title") as string;
        setTitle(params);
        fetchData(params);
      }, []);

      return (
        <>
        {
            data?
            <Layout heroProp={{showSlide:false, slideContent: heroData}}>
                <StylizedTextComponent
                focusText={data.CursiveContentSection.contentCursive}
                text={data.CursiveContentSection.content} />
                <RoomDetail
                bath={data.RoomDescriptionSection.bathroomSize}
                bedding={data.RoomDescriptionSection.bedding}
                buttonText="Reserve Now"
                guests={data.RoomDescriptionSection.guests}
                link="#"
                mobileroomImage={data.RoomDescriptionSection.imageUrl}
                number={data.RoomDescriptionSection.roomNumber}
                roomImage={data.RoomDescriptionSection.imageUrl}
                size={data.RoomDescriptionSection.roomSize}
                text={data.RoomDescriptionSection.roomDescription}
                title={data.RoomDescriptionSection.roomExcerpt} />
                <RoomFeatures 
                featuresArray={data.RoomDescriptionSection.roomFeatures}
                image={bullet}
                title="FEATURES & AMENITIES" />
                <FullWidthGalleryCarouselComponent
                images={data.GallerySection}
                route="#"
                routeLabel="Explore Gallery"
                title="GALLERY" />
                {
                    data.RelatedContentSection.map((content, index) => (
                        <BoxedCarouselComponent 
                        button="Learn More" 
                        carouselContent={[{
                            cardPosition: index %2 ===0? "Left": "Right", 
                            description: content.description,
                            mediaUrl: content.mediaUrl,
                            title: content.title,
                            link: `/room-details?title=${content.title}`
                        }]}
                        imagePosition={index %2 ===0? "Right": "Left"}
                        title={index === 0? "YOU MAY ALSO LIKE": ""}
                        imageCarousel={false} />
                        ))
                }


            </Layout>
            :
            <Loader/>
        }
        </>
      )
}
export default SingleRoom;